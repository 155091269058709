<template>
  <div class="container-program-v3">
    <Header />

    <tabs-up baseRoute="program" :category.sync="category" />

    <countdown class="countdown-container" v-if="time_starting && !showCountdown" :time="$moment(time_starting).diff($moment(new Date()), 'seconds') * 1000">
      <template slot-scope="props">
        <div class="count-down black">
          <p class="beach_floor--text">{{ props.days }} days</p>
          <p class="art_forest--text">{{ props.hours }} hours</p>
          <p class="swimming--text">{{ props.minutes }} min</p>
          <p class="gallerie--text">{{ props.seconds }} sec</p>
        </div>
        <div class="countdown-close" @click="hideCountdown">
          <v-icon color="secondary">close</v-icon>
        </div>
      </template>
    </countdown>

    <div class="stages-container">
      <div class="stages-program" ref="stagesContainer">
        <div class="current-time-bar" :style="getCurrentTimeBarStyle()"></div>
        <div class="hours-column sticky">

          <div class="hour-line" v-for="hour,idx in hourlyRows" :key="`hour-${idx}`">
            <small>{{ $moment(hour).format("D MMM") }}</small>
            {{ $moment(hour).format("LT") }}
          </div>

          <!-- <div class="hour-line">1 pm</div>
          <div class="hour-line">2 pm</div> -->
        </div>
        <div class="stage-column" v-for="stage,idx in stages" :key="`stage-${idx}`" :style="{ backgroundColor: stage.color }">
          <div class="stage-header" :style="getHeaderStyle(stage)">{{ stage.name }}</div>
          <div class="stage-content">

            <div class="hour-line clean" v-for="hour,idx4 in hourlyRows" :key="`hour-${idx4}`">
              <small>{{ $moment(hour).format("D MMM") }}</small>
              {{ $moment(hour).format("LT") }}
              <!-- <div class="schedule" v-for="schedule,idx3 in scheduleDay.schedules" :key="`schedule-${idx3}`">
                <h4>{{ schedule.attraction.name }}</h4>

                <p>{{ schedule.date_start }} {{ schedule.hour_start }}</p>
                <p>{{ schedule.date_end }} {{ schedule.hour_end }}</p>

              </div> -->
            </div>


            <!-- v-if="schedule.attraction.category !== 'category_crew'"  -->
            <router-link :to="`/schedule/${schedule.id}`" :style="getStyleForAttraction(schedule)" class="stage-attraction" v-for="schedule,idx2 in stage.groups_schedules.map(day => day.schedules).flat()" :key="`stage-day-${idx2}`">
              <h4 :style="getStyleForAttractionTitle(schedule)">{{ schedule.attraction.name }}</h4>
              <p v-if="schedule.attraction.colective_name">{{ schedule.attraction.colective_name }}</p>

              <p v-if="calculateStyleForAttraction(schedule).height > 50">{{ schedule.hour_start }}h</p>
              <!-- {{ schedule.date_start }}  -->
              <!-- <p>{{ schedule.date_end }} {{ schedule.hour_end }}</p> -->
            </router-link>


            <div></div>


          </div>
          <!-- {{ stage.color }}
          {{ stage.description }}
          {{ stage.name }}
          {{ stage.stage_type }}
          {{ stage.image_url }}
          {{ stage.id }}
          {{ stage.groups_schedules }} -->
          <!-- <pre>{{ stage }}</pre> -->
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Header from "@/components/Header";
import TabsUp from "@/components/TabsUp";
// import ProgramDay from "@/components/ProgramDay";

import StagesService from "@/services/stages.service";
import SchedulesService from "@/services/schedules.service";

export default {
  name: "Program",
  data: () => ({
    category: 'crew',
    time_starting: null,
    groups_schedules: [],
    // start: '2023-07-28 16:00:00',
    // end: '2023-07-31 08:00:00',
    start: '2024-08-02 15:00:00',
    end: '2024-08-06 08:00:00',
    // json.time_starting Time.new(2024, 8, 24, 15, 00, 00)
    // start: '2023-06-13 16:00:00',
    // end: '2023-06-15 08:00:00',
    events: [],
    stages: [],
    columnHeight: 100
  }),
  components: {
    Header,
    TabsUp,
    // ProgramDay,
  },
  watch: {
    // category: function (val) {
    //   if(val !== 'all') this.$store.dispatch('user/setShowCountdown', true)

    //   this.$router.push({ path: 'program', query: { category: val }})

    //   this.getSchedules()
    // }
    '$route.query.category': function (val) {
      this.category = val || 'crew'
      this.getSchedules()

      // voltar para o topo
      try {
        const container = document.querySelector(`.container-program`);
        if(container) {
          // console.log(container)
          container.scrollTop = 0
        }
      } catch(_) { console.log(_) }

      // console.log('mudei a rota para ', val)
    }
  },
  mounted() {
    // console.log(this.$route.query.category)
    if(this.$route.query.category) this.category = this.$route.query.category

    this.getSchedules();
    this.getStages();
  },
  beforeDestroy() {
    this.$store.dispatch('user/setShowCountdown', true)
  },
  computed: {
    cal () {
      return this.$refs.calendar
    },
    nowY () {
      // try {
        // console.log(this.$refs.calendar)
        return this.$refs.calendar.timeToY(this.$refs.calendar.times.now) + 'px'
      // } catch(_) {
        // console.log(_)
        // return '-10px'
      // }
      // return this.$refs.calendar ? this.$refs.calendar.timeToY(this.$refs.calendar.times.now) + 'px' : '-10px'
    },
    showCountdown() {
      return this.$store.getters['user/showCountdown']
    },
    // eventsMap () {
    //   const map = {}
    //   this.groups_schedules.forEach(e => (map[e.date] = map[e.date] || []).push(e))
    //   return map
    // },
    eventsMap () {
      const map = {}
      this.groups_schedules.forEach((e) => {
        return (map[e.date] = map[e.date] || []).push(e.schedules)
      })
      return map
    },

    // generate an array with each hour between today and 2 days from now using native js date
    hourlyRows () {
      const start = new Date(this.start)
      const end = new Date(this.end)
      const hours = []
      for (let i = start; i <= end; i.setHours(i.getHours() + 1)) {
        hours.push(new Date(i))
      }
      return hours

      // const rows = []
      // const start = Date.new
      // const end = start.clone().add(2, 'days')
      // const diff = end.diff(start, 'hours')
      // for (let i = 0; i < diff; i++) {
      //   rows.push(start.clone().add(i, 'hours'))
      // }
      // return rows
    }
  },
  methods: {
    scrollToTime () {
      // TODO: implement this
      // this.$nextTick(() => {
      // })
    },

    getEvents () {
      const events = []

      // console.log(this.groups_schedules)

      this.groups_schedules.map(group => {
        group.schedules.map(schedule => {
          // date_end
          // "2022-08-26"
          // hour_end
          // "18:00"
          const dateStart = `${schedule.date_start}T${schedule.hour_start}:00`
          const dateEnd = `${schedule.date_start}T${schedule.hour_start}:00`

          if (schedule.attraction.category === 'category_crew')
            console.log('filtered crew', schedule.attraction.name)
          else
            events.push({
              name: schedule.attraction.name,
              start: dateStart,
              end: dateEnd,
              color: schedule.stage.color || this.getEventColor(schedule.attraction.category),
              timed: true,
              id: schedule.id,
              slug: schedule.attraction.slug,
            })
        })
      })


      // // const min = new Date(`${start.date}T00:00:00`)

      //   const firstTimestamp = this.rnd(min.getTime(), max.getTime())
      //   const first = new Date(firstTimestamp - (firstTimestamp % 900000))
      //   const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
      //   const second = new Date(first.getTime() + secondTimestamp)

      this.events = events
    },

    getEventColor (categorySlug) {
      switch(categorySlug) {
        case 'category_crew':
          return '#f44336'
        default:
          return '#ccc'
      }
    },

    showEvent (evt) {
      // console.log(evt)
      this.$router.push({ path: `/schedule/${evt.event.id}` })
    },

    // end calendar

    async getSchedules() {
      // console.log(this.category)
      try {
        const response = await SchedulesService.getAll();

        this.time_starting = response.data.time_starting;
        this.groups_schedules = response.data.groups_schedules;

        this.getEvents()

        // this.scrollToTime()
        // this.updateTime()
        // this.groups_schedules.map((group, index) => {
        //   group.position = index;
        // });
      } catch (error) {
        console.error(error);
      }
    },
    sendPreviousDay(position) {
      if(position == 0) return;

      const container = this.$el.querySelector(`.program-${position - 1}`);
      if(container) container.scrollIntoView({ behavior: "smooth", block: "start" });
    },
    sendNextDay(position) {
      if(position + 1 == this.groups_schedules.length) return;

      const container = this.$el.querySelector(`.program-${position + 1}`);
      if(container) container.scrollIntoView({ behavior: "smooth", block: "start" });
    },

    onFavUpdated () {
      // console.log('this', params)
      // this bug because of our cache on program json
      // this.getSchedules()
    },

    async getStages () {
      try {
        const response = await StagesService.getAll();
        this.stages = response.data

        // console.log(this.stages)


        this.stages = this.stages.map(stage => {
          stage.groups_schedules = stage.groups_schedules.map(group => {
            // console.log('pre check', stage.name, group.schedules.length)
            group.schedules = group.schedules.filter(schedule => schedule.attraction.category !== 'category_crew')
            // console.log('post check', stage.name, group.schedules.length)

            return group
          })
          return stage
        })



        // console.log(this.stages
        // filter out category_crew
        // this.stages = response.data.filter(stage => stage.groups_schedules.filter(gs => gs.schedules.filter(s => s.attraction.category !== 'category_crew')))

      } catch (error) {
        console.error(error);
      }
    },

    /* eslint-disable */
    getStyleForAttraction (schedule) {

      const { top, height, backgroundColor, borderColor } = this.calculateStyleForAttraction(schedule)

      return {
        top: `${top}px`,
        position: 'absolute',
        height: `${height}px`,
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        fontSize: height <= 25 ? '0.8rem' : '1.0rem',
        overflow: height <= 25 ? 'scroll' : 'hidden',
      }
    },

    getStyleForAttractionTitle (schedule) {
      const { height } = this.calculateStyleForAttraction(schedule)

      const titleSize = schedule.attraction.name.length

      return {
        animation: height <= 35 && titleSize > 14 ? 'scroll-top-bottom 3s linear alternate forwards infinite' : '0',
      }
    },
    /* eslint-enable */

    calculateStyleForAttraction (schedule) {


      // 1 rem = 16px
      // const columnPadding = 16;

      // consider the css properties
      const columnHeight = this.columnHeight;
      const columnPadding = 0;

      // use to calc the top position
      const startColumnDateTime = new Date(this.start)
      const startTime = new Date(`${schedule.date_start}T${schedule.hour_start}:00`).getTime()
      const diffTime = startTime - startColumnDateTime.getTime()
      const diffHours = diffTime / 1000 / 60 / 60

      // use to calc the column height based on start and end time
      const endTime = new Date(`${schedule.date_end}T${schedule.hour_end}:00`).getTime()
      const diffTimeEnd = endTime - startTime
      const diffHoursEnd = diffTimeEnd / 1000 / 60 / 60

      // console.log(diffHours)
      const finalHeight = diffHoursEnd * columnHeight

      return {
        top: diffHours * columnHeight + columnPadding,
        height: finalHeight,
        backgroundColor: this.darkenrgba(this.hex2rgba(schedule.stage.color, 1), 20, .6),
        borderColor: this.darkenrgba(this.hex2rgba(schedule.stage.color, 1), 40, .7)
      }
    },

    getCurrentTimeBarStyle () {
      let width
      try {
        width = `${this.$refs.stagesContainer.scrollWidth}px`
      } catch(_) {
        width = '100%'
      }

      const columnHeight = this.columnHeight;

      // calculate the top position based on the current time considering minutes
      const startColumnDateTime = new Date(this.start)
      const startTime = new Date().getTime()
      const diffTime = startTime - startColumnDateTime.getTime()

      const diffMinutes = diffTime / 1000 / 60

      const top = `${diffMinutes * (columnHeight / 60) + columnHeight}px`

      if(diffMinutes < 0) return { display: 'none' }

      return {
        // top: `${20 * columnHeight}px`,
        top: top,
        zIndex: 10,
        width,
      }
    },

    getHeaderStyle (stage) {
      return {
        backgroundColor: this.darkenrgba(this.hex2rgba(stage.color, 1), 20, 1),
        borderColor: this.darkenrgba(this.hex2rgba(stage.color, 1), 40, 1),
        color: this.darkenrgba(this.hex2rgba(stage.color, 1), 90, 1),
      }
    },

    hex2rgba (hex, alpha = 1) {
      const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
      return `rgba(${r},${g},${b},${alpha})`;
    },

    darkenrgba(rgba, percentage = 10, alpha = 1) {
      // , _a
      const [r, g, b] = rgba.match(/\d+/g).map(x => parseInt(x, 10));
      return `rgba(${r - percentage},${g - percentage},${b - percentage},${alpha})`;
    },

    hideCountdown() {
      // this.$store.dispatch('user/setShowCountdown', false)
      this.$store.dispatch('user/setShowCountdown', true)
    }

  },
};
</script>

<style lang="scss" scoped>
.program-calendar {
  margin-top: 0.2rem;
  // min-height: 80vh;
  background-color: transparent !important;
  border-left: 0 !important;
  border-top: 0 !important;
}
</style>
<style lang="scss">
.container-program-v3 {
  // height: calc(100vh - 200px);

  margin-top: calc(100px + env(safe-area-inset-top));

  // padding-bottom: 120px;
  max-height: 100vh;

  overflow-y: scroll;

  .count-down {
    padding: 25px;

    font-weight: 700;
    font-size: 76px;
  }
}
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}


.stages-container {



    height: calc(100vh - env(safe-area-inset-top));
  // v3
  // height: 100vh

  .stages-program {
    display: flex;
    position: relative;
    overflow-x: scroll;
    overflow-y: scroll;
    width: 100%;
    height: 100%;

    .current-time-bar {
      position: absolute;
      height: 3px;
      background: rgba(255, 0, 0, .4);
      width: 100%;
      left: 0;
    }

    .hours-column {
      min-height: 100vh;
      min-width: 130px;
      padding-top: 90px;
      height: fit-content;

      &.sticky {
        position: sticky;
        left: 0;
        z-index: 20;
        background-color: #180523;
        min-width: 80px;
      }
    }

    .hour-line {
      padding: 1rem;
      color: white;
      height: 100px;
      &.clean {
        opacity: .2;
        padding-top: 6px;
      }

      small {
        display: block;
        font-size: 0.8rem;
      }
    }

    .stage-column {
      min-height: 100vh;
      min-width: 160px;
      height: max-content;

      .stage-header {
        font-weight: bold;
        padding: 1rem;
        height: 100px;


        /* WIP/TODO: review this */
        position: sticky;
        top: 0px;
        // background: rgba(255, 255, 255, 1);
        z-index: 10;
        height: 70px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 1px 2px 14px -7px rgba(0,0,0,0.7);
        border-left: 1px solid;
        border-bottom: 1px solid;
        font-weight: normal;
        font-size: 1rem;
      }

      .stage-content {
        // padding: 0.3rem;
        position: relative;
        margin-top: 30px;

        .stage-attraction {
          background: rgba(255, 255, 255, 0.8);
          border: 1px solid #111;
          border-radius: 4px;
          padding: 0.3rem;
          text-decoration: none;
          width: 100%;

          h4 {

          }

          p {
            font-size: 0.8rem;
          }
        }
      }


    }
  }
}

.countdown-container {
  position: relative;
  display: block;
  .countdown-close {
    position: absolute;
    top: 20px;
    right: 20px;
    // padding: 10px;
    cursor: pointer;
  }
}

@keyframes scroll-top-bottom {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-65%);
  }
}

</style>
