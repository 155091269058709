<template>
  <div class="container-program">
    <Header />

    <tabs-up baseRoute="program" :category.sync="category" />

    <countdown v-if="time_starting && category == 'crew' && !showCountdown" :time="$moment(time_starting).diff($moment(new Date()), 'seconds') * 1000">
      <template slot-scope="props">
        <div class="count-down black">
          <p class="beach_floor--text">{{ props.days }} days</p>
          <p class="art_forest--text">{{ props.hours }} hours</p>
          <p class="swimming--text">{{ props.minutes }} min</p>
          <p class="gallerie--text">{{ props.seconds }} sec</p>
        </div>
      </template>
    </countdown>

    <program-day
      v-for="(group, index) of groups_schedules"
      :group="group"
      :key="group.date"
      :total="groups_schedules.length"
      :position="index"
      :sendPreviousDay="sendPreviousDay"
      :sendNextDay="sendNextDay"
      @onFavUpdated="onFavUpdated"
    />
  </div>
</template>

<script>
import Header from "@/components/Header";
import TabsUp from "@/components/TabsUp";
import ProgramDay from "@/components/ProgramDay";

import SchedulesService from "@/services/schedules.service";

export default {
  name: "Program",
  data: () => ({
    category: 'crew',
    time_starting: null,
    groups_schedules: [],
  }),
  components: {
    Header,
    TabsUp,
    ProgramDay,
  },
  watch: {
    // category: function (val) {
    //   if(val !== 'all') this.$store.dispatch('user/setShowCountdown', true)

    //   this.$router.push({ path: 'program', query: { category: val }})

    //   this.getSchedules()
    // }
    '$route.query.category': function (val) {
      this.category = val || 'crew'
      this.getSchedules()

      // voltar para o topo
      try {
        const container = document.querySelector(`.container-program`);
        if(container) {
          console.log(container)
          container.scrollTop = 0
        }
      } catch(_) { console.log(_) }

      // console.log('mudei a rota para ', val)
    }
  },
  mounted() {
    // console.log(this.$route.query.category)
    if(this.$route.query.category) this.category = this.$route.query.category

    this.getSchedules();
  },
  beforeDestroy() {
    this.$store.dispatch('user/setShowCountdown', true)
  },
  computed: {
    showCountdown() {
      return this.$store.getters['user/showCountdown']
    }
  },
  methods: {
    async getSchedules() {
      // console.log(this.category)
      try {
        const response = await SchedulesService.getAll(this.category);

        this.time_starting = response.data.time_starting;
        this.groups_schedules = response.data.groups_schedules;
      } catch (error) {
        console.error(error);
      }
    },
    sendPreviousDay(position) {
      if(position == 0) return;

      const container = this.$el.querySelector(`.program-${position - 1}`);
      if(container) container.scrollIntoView({ behavior: "smooth", block: "start" });
    },
    sendNextDay(position) {
      if(position + 1 == this.groups_schedules.length) return;

      const container = this.$el.querySelector(`.program-${position + 1}`);
      if(container) container.scrollIntoView({ behavior: "smooth", block: "start" });
    },

    onFavUpdated () {
      // console.log('this', params)
      // this bug because of our cache on program json
      // this.getSchedules()
    }

  },
};
</script>

<style lang="scss">
.container-program {
  height: calc(100vh - 150px);

  margin-top: calc(100px + env(safe-area-inset-top));

  padding-bottom: 120px;

  overflow-y: scroll;

  .count-down {
    padding: 25px;

    font-weight: 700;
    font-size: 76px;
  }
}
</style>