<template>
  <div
    class="toggle"
    :class=[state_class]>
    <div
      class="draggable"
      @touchmove.prevent="dragStart"
      :style="style">
    </div>
  </div>
</template>

<script>
export default {
  name: 'switch-toggle',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      width: 196,
      position: 0,
    }
  },
  mounted() {
    this.toggle(this.value)
  },
  computed: {
    style() {
      return {
        transform: `translateX(${this.position}px)`
      }
    },
    state_class() {
      return this.value ? 'active' : 'disable'
    }
  },
  methods: {
    toggle(state) {
      this.position = !state ? 0 : this.width
    },
    dragging(e) {
      const pos = e.changedTouches[0].clientX - this.$el.offsetLeft;

      let percent = pos;
      percent = percent <= 0 ? 0 : percent;
      percent = percent > this.width ? this.width : percent;

      this.position = percent;
    },
    dragStart() {
      window.addEventListener('touchmove', this.dragging);
      window.addEventListener('touchend', this.dragStop);
    },
    dragStop() {
      window.removeEventListener('touchmove', this.dragging);
      window.removeEventListener('touchend', this.dragStop);

      this.toggle(this.position >= (this.width / 2));
      this.emit(this.position >= (this.width / 2));
    },
    emit(state) {
      this.$emit('input', state)
    }
  }
}
</script>

<style lang="scss">
  .toggle {
    position: relative;

    width: 271px;
    height: 73px;
    background-color: #180523;
    border-radius: 200px;
    transition: background 0.6s;
    
    .draggable {
      position: absolute;

      left: 4px;
      bottom: 4px;

      height: 66px;
      width: 66px;

      background-color: #00C97B;
      border-radius: 100%;
      transform: translateX(0px);
    }
    
    &.active {
      background: #FFB0E2 ;
      transition: background 0.6s;
    }
  }
</style>