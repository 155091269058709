export default {
  namespaced: true,
  state: {
    loggedIn: false,
    user: null,
    register_form: {
      name: "",
      email: "",
      password: "",
      password_confirm: "",
    },
    register_avatar: {
      preview: null,
      file: null,
    },
    register_page: 0,
    pushToken: {
      value: ''
    },
    show_countdown: false,
  },
  getters: {
    loggedIn: state => {
      return state.loggedIn
    },
    token: state => {
      return state.user && state.user.token ? state.user.token : null;
    },
    pushToken: state => {
      return state.pushToken
    },
    showCountdown: state => {
      return state.show_countdown
    }
  },
  actions: {
    login({ commit }, payload) {
      commit('loginSuccess', payload);
    },
    updateUser({ commit }, payload) {
      commit('updateUser', payload);
    },
    logout({ commit }) {
      commit('logout');
    },
    changeSite({ commit }, site) {
      commit('changeSite', site);
    },
    registerPage({ commit }, payload) {
      commit('registerPage', payload);
    },
    cleanRegister({ commit }) {
      commit('cleanRegister');
    },
    registerPushToken({ commit }, payload) {
      commit('registerPushToken', payload);
    },
    setShowCountdown({ commit }, payload) {
      commit('setShowCountdown', payload);
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.loggedIn = true;
      state.user = user.data;
    },
    updateUser(state, user) {
      state.user = user;
    },
    logout(state) {
      state.loggedIn = false;
      state.user = null;
      state.show_countdown = false;
    },
    registerPage(state, page) {
      state.register_page = page;
    },
    registerPushToken(state, params) {
      state.pushToken = params
    },
    setShowCountdown(state, params) {
      state.show_countdown = params
    },
    cleanRegister(state) {
      state.register_form = {
        name: "",
        email: "",
        password: "",
        password_confirm: "",
      };

      state.register_avatar = {
        preview: null,
        file: null,
      };

      state.register_page = 0;
    },
  }
};
