<template>
  <v-tabs
    class="tabs-up"
    background-color="#180523"
    color="secondary"
    dark
    centered
  >
    <!-- <v-tab v-on:click="updateCategory('all')">All</v-tab> -->

    <v-tab v-if="isOnScheduleRoute" exact to="/program_v3">All</v-tab>
    <!-- <v-tab v-else :to="`/${baseRoute}?category=all`">All</v-tab> -->

    <v-tab exact :to="`/${baseRoute}?category=crew`">Crews</v-tab>
    <v-tab exact :to="`/${baseRoute}?category=artist`">Artists</v-tab>
    <v-tab exact :to="`/${baseRoute}?category=performance`">Performances</v-tab>
    <v-tab exact :to="`/${baseRoute}?category=movie`">Movies</v-tab>
    <v-tab exact :to="`/${baseRoute}?category=talk`">Talks</v-tab>
    <!-- <v-tab exact :to="`/${baseRoute}?category=side_event`">Side Events</v-tab> -->
    <v-tab exact :to="`/${baseRoute}?category=activities`">Activities</v-tab>
    <v-tab exact :to="`/${baseRoute}?category=installation`">Installations</v-tab>
  </v-tabs>
</template>

<script>
  export default {
    name: "tabs-up",
    props: {
      category: String,
      baseRoute: String
    },
    methods: {
      // updateCategory(val) {
      //   this.$emit('update:category', val)
      // }
    },
    computed: {
      isOnScheduleRoute () {
        // console.log(this.$route.name)
        return ['Program','ProgramV3'].includes(this.$route.name)
      }
    }
  };
</script>

<style lang="scss">
  .v-slide-group__prev {
    display: none !important;
  }

  .tabs-up {
    position: fixed;

    z-index: 5;

    top: calc(56px + env(safe-area-inset-top));

    font-size: 14px;

    line-height: 21px;

    .v-tab {
      text-transform: capitalize !important;
    }
  }
</style>
