import instance from './base.service';

class SchedulesService {
  getAll(category) {
    return instance.get(`api/schedules.json?category=${category}`)
  }

  show(id) {
    return instance.get(`api/schedules/${id}.json`)
  }

  lineup() {
    return instance.get(`api/schedules/lineup.json`)
  }
}

export default new SchedulesService();
