import axios from 'axios';

import store from '../store';

// create a new axios instance
const instance = axios.create({
  baseURL: 'https://app.wholefestival.shop'
  // baseURL: 'http://192.168.1.101:3000'
  // baseURL: 'http://localhost:3000'
});

instance.interceptors.request.use(config => {
  if(store.getters['user/loggedIn']) {
    config.headers.Authorization = `Bearer ${store.getters['user/token']}`
  }

  return config
})


export default instance
