import instance from './base.service';

class FaqsService {
  getAll() {
    return instance.get(`api/faqs.json`)
  }

  show(id) {
    return instance.get(`api/faqs/${id}.json`)
  }
}

export default new FaqsService();
