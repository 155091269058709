<template>
  <div class="container-faq">
    <div class="header">
      <v-icon color="gallerie" large @click="$router.go(-1)">arrow_back</v-icon>

      <p class="title">FAQ</p>
    </div>

    <v-expansion-panels accordion dark>
      <v-expansion-panel v-for="faq in faqs" :key="faq.id">
        <v-expansion-panel-header>{{faq.title}}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <p class="text">{{faq.presentation_text}}</p>

          <p class="subtext">{{faq.description}}</p>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import FaqsService from "@/services/faqs.service"

export default {
  name: "Faq",
  data: () => ({
    faqs: [],
  }),
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        const response = await FaqsService.getAll();

        this.faqs = response.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
.container-faq {
  height: 100vh;

  overflow-y: scroll;

  background-color: #27083a;

  .header {
    padding: 46px 50px 30px 24px;
  }

  color: #cc7aff;

  .title {
    font-weight: 700;
    font-size: 47px;
    line-height: 56px;

    margin-top: 10px;
  }

  .v-expansion-panels {
    border-top: 1px solid #cc7aff;
  }


  .v-expansion-panel {
    background-color: transparent !important;
    color: #cc7aff !important;

    border-bottom: 1px solid #cc7aff;

    border-radius: 0px;

    .v-expansion-panel-header {
      font-size: 18px;
      line-height: 27px;

      .mdi:before,
      .mdi-set {
        color: #cc7aff;
      }
    }

    .v-expansion-panel-content {
      padding: 45px 25px;
      .text {
        font-size: 29px;
        line-height: 34px;

        margin-bottom: 20px;
      }

      .subtext {
        font-size: 18px;
        line-height: 27px;

        margin-bottom: 20px;
      }
    }
  }
}
</style>