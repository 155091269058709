<template>
  <div class="container-program">
    <Header />

    <tabs-up baseRoute="program" :category.sync="category" />

    <countdown v-if="time_starting && category == 'crew' && !showCountdown" :time="$moment(time_starting).diff($moment(new Date()), 'seconds') * 1000">
      <template slot-scope="props">
        <div class="count-down black">
          <p class="beach_floor--text">{{ props.days }} days</p>
          <p class="art_forest--text">{{ props.hours }} hours</p>
          <p class="swimming--text">{{ props.minutes }} min</p>
          <p class="gallerie--text">{{ props.seconds }} sec</p>
        </div>
      </template>
    </countdown>

    <!-- <v-layout wrap>
      <v-flex
        xs12
        class="mb-3 mt-4"
      > -->
        <!-- <v-sheet height="500"> -->
          <v-calendar
            class="program-calendar"
            ref="calendar"
            v-model="start"
            :events="events"
            :type="type"
            :end="end"
            @click:event="showEvent"
            mode="stack"
            dark
            color="primary"
            :interval-height="100"
            :interval-width="50"
            :max-days="3"
            event-overlap-mode="stack"
            :event-height="40"
            event-overlap-threshold="10"
          >
            <!-- mode="stack" mode="column" -->
            <template v-slot:day-body="{ date, week }">
              <div
                class="v-current-time"
                :class="{ first: date === week[0].date }"
                :style="{ top: nowY }"
              >
              </div>
            </template>
          </v-calendar>
        <!-- </v-sheet> -->
      <!-- </v-flex> -->

      <!-- {{ eventsMap }} -->
<v-select
        v-model="type"
        :items="typeOptions"
        label="Type"
      ></v-select>
      <!-- 
        
    </v-layout> -->

  </div>
</template>

<script>
import Header from "@/components/Header";
import TabsUp from "@/components/TabsUp";
// import ProgramDay from "@/components/ProgramDay";

import SchedulesService from "@/services/schedules.service";

export default {
  name: "Program",
  data: () => ({
    category: 'crew',
    time_starting: null,
    groups_schedules: [],
    // calendar related
    type: '4day',
    start: '2022-08-26',
    end: '2022-08-29',
    typeOptions: [
      { text: 'Day', value: 'day' },
      { text: '4 Day', value: '4day' },
      { text: 'Week', value: 'week' }
    ],
    events: [],
    tracked: {
        '2019-01-09': [23, 45, 10],
        '2019-01-08': [10],
        '2019-01-07': [0, 78, 5],
        '2019-01-06': [0, 0, 50],
        '2019-01-05': [0, 10, 23],
        '2019-01-04': [2, 90],
        '2019-01-03': [10, 32],
        '2019-01-02': [80, 10, 10],
        '2019-01-01': [20, 25, 10],
      },
      colors: ['#1867c0', '#fb8c00', '#000000'],
      categories: ['Development', 'Meetings', 'Slacking'],
  }),
  components: {
    Header,
    TabsUp,
    // ProgramDay,
  },
  watch: {
    // category: function (val) {
    //   if(val !== 'all') this.$store.dispatch('user/setShowCountdown', true)

    //   this.$router.push({ path: 'program', query: { category: val }})

    //   this.getSchedules()
    // }
    '$route.query.category': function (val) {
      this.category = val || 'crew'
      this.getSchedules()

      // voltar para o topo
      try {
        const container = document.querySelector(`.container-program`);
        if(container) {
          console.log(container)
          container.scrollTop = 0
        }
      } catch(_) { console.log(_) }

      // console.log('mudei a rota para ', val)
    }
  },
  mounted() {
    // console.log(this.$route.query.category)
    if(this.$route.query.category) this.category = this.$route.query.category

    this.getSchedules();
  },
  beforeDestroy() {
    this.$store.dispatch('user/setShowCountdown', true)
  },
  computed: {
    cal () {
      return this.$refs.calendar
    },
    nowY () {
      // try {
        // console.log(this.$refs.calendar)
        return this.$refs.calendar.timeToY(this.$refs.calendar.times.now) + 'px'
      // } catch(_) {
        // console.log(_)
        // return '-10px'
      // }
      // return this.$refs.calendar ? this.$refs.calendar.timeToY(this.$refs.calendar.times.now) + 'px' : '-10px'
    },
    showCountdown() {
      return this.$store.getters['user/showCountdown']
    },
    // eventsMap () {
    //   const map = {}
    //   this.groups_schedules.forEach(e => (map[e.date] = map[e.date] || []).push(e))
    //   return map
    // },
    eventsMap () {
      const map = {}
      this.groups_schedules.forEach((e) => {
        return (map[e.date] = map[e.date] || []).push(e.schedules)
      })
      return map
    }
  },
  methods: {
    // calendar
    getCurrentTime () {
      return 
    },
    scrollToTime () {
      this.$nextTick(() => {
        const time = this.$refs.calendar ? this.$refs.calendar.times.now.hour * 60 + this.$refs.calendar.times.now.minute : 0
        const first = Math.max(0, time - (time % 30) - 30)
        this.$refs.calendar.scrollToTime(first)
      })
    },
    updateTime () {
      this.$refs.calendar.updateTimes()
      // this.nowY;
      // console.log( 'teste')
      // setInterval(() => this.$refs.calendar.updateTimes(), 60 * 1000)
    },

    getEvents () {
      const events = []

      console.log(this.groups_schedules)

      this.groups_schedules.map(group => {
        group.schedules.map(schedule => {
          // date_end
          // "2022-08-26"
          // hour_end
          // "18:00"
          const dateStart = `${schedule.date_start}T${schedule.hour_start}:00`
          const dateEnd = `${schedule.date_start}T${schedule.hour_start}:00`
          events.push({
            name: schedule.attraction.name,
            start: dateStart,
            end: dateEnd,
            color: schedule.stage.color || this.getEventColor(schedule.attraction.category),
            timed: true,
            id: schedule.id,
            slug: schedule.attraction.slug,
          })
        })
      })


      // // const min = new Date(`${start.date}T00:00:00`)
      
      //   const firstTimestamp = this.rnd(min.getTime(), max.getTime())
      //   const first = new Date(firstTimestamp - (firstTimestamp % 900000))
      //   const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
      //   const second = new Date(first.getTime() + secondTimestamp)

      this.events = events
    },
    getEventColor (categorySlug) {
      switch(categorySlug) {
        case 'category_crew':
          return '#f44336'
        default:
          return '#ccc'
      }
    },

    showEvent (evt) {
      // console.log(evt)
      this.$router.push({ path: `/schedule/${evt.event.id}` })
    },

    // end calendar

    async getSchedules() {
      // console.log(this.category)
      try {
        const response = await SchedulesService.getAll();

        this.time_starting = response.data.time_starting;
        this.groups_schedules = response.data.groups_schedules;

        this.getEvents()

        this.scrollToTime()
        this.updateTime()
        // this.groups_schedules.map((group, index) => {
        //   group.position = index;
        // });
      } catch (error) {
        console.error(error);
      }
    },
    sendPreviousDay(position) {
      if(position == 0) return;

      const container = this.$el.querySelector(`.program-${position - 1}`);
      if(container) container.scrollIntoView({ behavior: "smooth", block: "start" });
    },
    sendNextDay(position) {
      if(position + 1 == this.groups_schedules.length) return;

      const container = this.$el.querySelector(`.program-${position + 1}`);
      if(container) container.scrollIntoView({ behavior: "smooth", block: "start" });
    },

    onFavUpdated () {
      // console.log('this', params)
      // this bug because of our cache on program json
      // this.getSchedules()
    }

  },
};
</script>

<style lang="scss" scoped>
.program-calendar {
  margin-top: 0.2rem;
  // min-height: 80vh;
  background-color: transparent !important;
  border-left: 0 !important;
  border-top: 0 !important;
}
</style>
<style lang="scss">
.container-program {
  height: calc(100vh - 150px);

  margin-top: calc(100px + env(safe-area-inset-top));

  padding-bottom: 120px;

  overflow-y: scroll;

  .count-down {
    padding: 25px;

    font-weight: 700;
    font-size: 76px;
  }
}
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>