import instance from './base.service';

class StagesService {
  getAll() {
    return instance.get(`api/stages.json`)
  }

  show(id) {
    return instance.get(`api/stages/${id}.json`)
  }
}

export default new StagesService();
