<template>
  <div
    :class="['container-program-item']"
    :style="style"
    @click="navigationToSchedule()"
  >
    <div class="program-content">
      <div class="icon" @click.stop.prevent="onFavClick(program)">
        <icon-heart v-if="!program.favorite_id"/>
        <icon-heart-fill v-else/>
      </div>

      <transition>
        <div class="reminder-overlay" v-if="isShowReminder">
          <div class="reminder-content">
            <p>do you want to be reminded 30 minutes before?</p>
            <div class="buttons">
              <button class="button" @click.stop.prevent="onYesClicked(program)">YES</button>
              <button class="button" @click.stop.prevent="onNoClicked(program)">NO</button>
            </div>
          </div>
        </div>
      </transition>

      <div class="content">
        <p :class="['title', { 'big-title': program.attraction.name.length > 50 }]">{{ program.attraction.name }}</p>
        <p class="subtitle" v-if="program.attraction.description">
          {{ program.attraction.description }}
        </p>
      </div>

      <div class="footer">
        <div class="footer-left">
          <div>
            <p>{{weekDay}}</p>
            <p class="subtitle">{{ program.hour_start }}</p>
          </div>

          <div>
            <p>STAGE</p>
            <p class="subtitle">{{ program.stage.name }}</p>
          </div>
        </div>
      </div>

      <div class="icon">
        <icon-arrow />
      </div>
    </div>
  </div>
</template>

<script>
import FavoritesService from "@/services/favorites.service";
export default {
  name: "program-item",
  data: () => ({
    week_days: [
      'SUN',
      'MON',
      'TUE',
      'WED',
      'THU',
      'FRI',
      'SAT',
    ],
    isShowReminder: false
  }),
  props: {
    program: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    style() {
      return `background-color: ${this.program.stage.color}`;
    },
    weekDay() {
      return this.$moment(this.program.date_start).locale('en').format('ddd')
      // return this.week_days[this.$moment(this.program.date_start, "Europe/Berlin").day()]
    },
  },
  methods: {
    navigationToSchedule() {
      this.$router.push(`/schedule/${this.program.id}`);
    },

    async onFavClick (scheduleItem) {
      if(scheduleItem.favorite_id) {
        scheduleItem.favorite_id = await this.removeToFavorites(scheduleItem.favorite_id)
        this.$emit('onFavUpdated', scheduleItem)
      } else {
        this.isShowReminder = true
      }
    },

    async onYesClicked (scheduleItem) {
      scheduleItem.favorite_id = await this.addToFavorites(scheduleItem.id)
      this.$emit('onFavUpdated', scheduleItem)
      this.isShowReminder = false
    },

    async onNoClicked (scheduleItem) {
      scheduleItem.favorite_id = await this.addToFavorites(scheduleItem.id)
      this.$emit('onFavUpdated', scheduleItem)
      this.isShowReminder = false
    },

    async addToFavorites(scheduleId) {
      try {
        const response = await FavoritesService.create(scheduleId);

        return response.data.favorite_id;
      } catch (error) {
        console.error(error);
      }
    },

    async removeToFavorites(scheduleId) {
      try {
        await FavoritesService.remove(scheduleId);

        return null;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
.container-program-item {

  position: relative;

  .reminder-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    display: flex;
    backdrop-filter: blur(10px);
    .reminder-content {
      background-color: #180523;
      color: white;
      min-width: 300px;
      min-height: 70px;
      padding: 1rem;

      .buttons {
        .button {
          font-size: 18px;
          padding: 0.5rem 0.8rem;
          font-weight: bold;
          margin-right: 1rem;
        }
      }
    }
  }

  .program-content {
    display: grid;
    grid-template-columns: 1fr 10fr 1fr;
    grid-template-rows: 1fr 10fr;

    height: 196px;

    padding: 20px 16px;

    .content {
      align-self: center;
    }

    .icon {
      height: 24px;
      width: 24px;

      margin-top: 8px;
    }

    .title {
      font-weight: 700;
      font-size: 29px;
      line-height: 35px;

      color: #27083a;

      &.big-title {
        // line-break: anywhere;
        font-size: 23px;
      }

    }

    .subtitle {
      font-size: 18px;
      line-height: 27px;

      color: #27083a;
    }

    .footer {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      grid-row: 2;
      grid-column: 2;

      font-size: 12px;
      line-height: 14px;

      color: #27083a;

      .subtitle {
        font-size: 14px;
        line-height: 21px;
      }

      .footer-left {
        display: flex;

        div + div {
          margin-left: 20px;
        }
      }
    }
  }

}
</style>
