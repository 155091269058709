import Vue from 'vue';
import Vuetify from 'vuetify';
import '@/stylesheets/vuetify.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#180523',
        secondary: '#FFB0E2',
        black: '#27083A',
        accent: '#7B5A97',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        gallerie: '#CC7AFF',
        beach_floor: "#F99750",
        performance: '#F2C94C',
        ambient: '#FFB0E2',
        crane: '#FF6853',
        art_forest: '#00C97B',
        swimming: "#869CFF"
      },
    },
  },
});
