import instance from './base.service';

class LiveUpdatesService {
  getAll() {
    return instance.get(`api/live_updates.json`)
  }

  show(id) {
    return instance.get(`api/live_updates/${id}.json`)
  }
}

export default new LiveUpdatesService();
