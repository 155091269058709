<template>
  <div class="container-resgiter-avatar">
    <div class="header">
      <div @click="page = 0">
        <icon-arrow color="#ffb0e2" :left="true"/>
      </div>

      <p class="title">Create account</p>
    </div>

    <p class="subtext">Great!</p>

    <p class="subtext">Now this one is optional.</p>

    <p class="subtext">
      Add a profile picture which will be shown on your posts and messages in
      our in-app community.
    </p>

    <div class="card-avatar" for="input-avatar" @click="takePicture()">
      <img :src="form.preview" v-if="form.preview" alt="avatar" />
      <img src="@/assets/avatar.svg" v-if="!form.preview" alt="avatar" />

      <v-btn text color="#5573FF">+ ADD</v-btn>
    </div>

    <div class="container-button">
      <v-btn color="#5573FF" dark @click="onSubmit()">DONE</v-btn>
    </div>
  </div>
</template>

<script>
import { Camera, CameraResultType } from '@capacitor/camera';

export default {
  name: "resgister-avatar",
  data: () => ({}),
  computed: {
    form() {
      return this.$store.state.user.register_avatar;
    },
    page: {
      get() {
        return this.$store.state.user.register_page;
      },
      set(value) {
        this.$store.dispatch("user/registerPage", value);
      },
    },
  },
  methods: {
    async takePicture() {
      try {
        await Camera.requestPermissions(["camera", "photos"]);

        const image = await Camera.getPhoto({
          quality: 50,
          allowEditing: false,
          resultType: CameraResultType.Base64,
          webUseInput: true,
        });

        this.form.preview = `data:image/jpeg;base64,${image.base64String}`;
        this.form.file = image;

      } catch (error) {
        this.$store.commit('loader/setLoading', false)
        console.error("error", error)
      }
    },
    onSubmit() {
      this.page = 2;
    }
  },
};
</script>

<style lang="scss">
.container-resgiter-avatar {
  color: #ffb0e2;

  .title {
    font-weight: 700;
    font-size: 47px;
    line-height: 56px;

    margin-top: 10px;
    margin-bottom: 30px;
  }

  .subtext {
    font-size: 18px;
    line-height: 27px;

    margin-bottom: 20px;
  }

  .card-avatar {
    display: flex;
    align-items: center;

    margin-top: 50px;

    img {
      width: 140px;
      height: 140px;

      object-fit: cover;

      border-radius: 50%;
    }

    .v-btn {
      margin-left: 30px;

      font-size: 18px;
    }
  }
  .container-button {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    margin-top: 10px;
  }
}
</style>