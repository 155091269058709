import instance from './base.service';

class SchedulesService {
  getAll(category) {
    return instance.get(`api/favorites.json?category=${category}`)
  }

  create(schedule_id) {
    return instance.post(`api/favorites.json`, {
      schedule_id
    });
  }

  remove(id) {
    return instance.delete(`api/favorites/${id}.json`);
  }
}

export default new SchedulesService();
