<template>
  <div
    :class="['container-lineup-item']"
    :style="style"
    @click="navigationToSchedule()"
  >
    <v-icon color="primary">{{ program.favorite_id ? 'favorite' : 'favorite_border'}}</v-icon>

    <p class="title-item">{{ program.attraction.name }}</p>

    <v-icon color="primary">arrow_forward</v-icon>
  </div>
</template>

<script>
export default {
  name: "program-item",
  props: {
    program: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    style() {
      return `background-color: ${this.program.stage.color}`;
    },
  },
  methods: {
    navigationToSchedule() {
      this.$router.push(`/schedule/${this.program.id}`);
    },
  },
};
</script>

<style lang="scss">
.container-lineup-item {
  display: flex;
  justify-content: space-between;

  min-height: 100px;

  padding: 20px;

  .title-item {
    flex: 1;

    font-weight: 700;
    font-size: 29px;
    line-height: 35px;

    margin-left: 15px;

    color: #27083a;
  }
}
</style>