<template>
  <div class="container-live-update-details">
    <v-icon color="crane" large @click="$router.go(-1)">arrow_back</v-icon>

    <p class="subtitle">Live Updates</p>

    <p class="title">{{live_update.title}}</p>

    <p class="text">{{$moment(live_update.created_at).format("DD.MM.YY-HH:mm")}}</p>

    <p class="text">
      {{live_update.description}}
    </p>
  </div>
</template>

<script>
import LiveUpdatesService from "@/services/live.updates.service";

export default {
  name: "LiveUpdateDetails",
  data: () => ({
    live_update: {},
  }),
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        const response = await LiveUpdatesService.show(this.$route.params.id);

        this.live_update = response.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
.container-live-update-details {
  height: 100vh;

  overflow-y: scroll;

  background-color: #27083a;

  padding: 34px 50px 34px 34px;

  color: #ff6853;

  .title {
    font-weight: 700;
    font-size: 47px;
    line-height: 56px;

    margin-top: 50px;
    margin-bottom: 15px;
  }

  .subtitle {
    font-size: 29px;
    line-height: 34px;

    margin-top: 10px;

    margin-bottom: 20px;
  }

  .text {
    font-size: 18px;
    line-height: 27px;

    margin-bottom: 20px;
  }
}
</style>