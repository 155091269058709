import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from "vuex-persistedstate";

import user from "./modules/user";

Vue.use(Vuex)

const store = new Vuex.Store({
  namespaced: true,
  state: {},
  mutations: {},
  modules: {
    user,
  },
  plugins: [createPersistedState({
    key: 'wholefestivalapp',
  })],
})

export default store;
