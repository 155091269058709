import Vue from 'vue'
import AppVue from './App.vue'

import { App } from '@capacitor/app';

import { Plugins, Capacitor } from '@capacitor/core';
const { PushNotifications } = Plugins;

import router from './router'
import store from './store';

if (Capacitor.getPlatform() !== 'web') {
  PushNotifications.addListener("registration", async (token) => {
    store.commit('user/registerPushToken', token)
    console.log('onRegistration', token)
  });

  PushNotifications.addListener('pushNotificationReceived', async () => {
    try {
      // const result = JSON.parse(notification.notification.data.payload)
      router.push('/live-updates')
    } catch(_) {
      console.log(_)
    }
  });

  PushNotifications.addListener('pushNotificationActionPerformed', async (notification) => {
    try {
      console.log('Push notification action performed', notification.notification.data.payload);
      const result = JSON.parse(notification.notification.data.payload)
      router.push('/live-updates/'+result.data.id)
    } catch(_) {
      console.log(_)
    }
  });

  PushNotifications.requestPermissions().then(result => {
    if (result.receive === 'granted') {
      // Register with Apple / Google to receive push via APNS/FCM
      PushNotifications.register();
    } else {
      // Show some error
    }
  });
}

import IconArrow from '@/assets/icons/arrow'
import IconHeart from '@/assets/icons/heart'
import IconHeartFill from '@/assets/icons/heart_fill'
import IconEdit from '@/assets/icons/edit'
import IconUserMinus from '@/assets/icons/user_minus'
import IconUserPlus from '@/assets/icons/user_plus'

Vue.component('icon-arrow', IconArrow)
Vue.component('icon-heart', IconHeart)
Vue.component('icon-heart-fill', IconHeartFill)
Vue.component('icon-edit', IconEdit)
Vue.component('icon-user-minus', IconUserMinus)
Vue.component('icon-user-plus', IconUserPlus)

App.addListener('appUrlOpen', function (event) {
  // Example url: https://beerswift.app/tabs/tabs2
  // slug = /tabs/tabs2
  const slug = event.url.split('.app').pop();

  // We only push to the route if there is a slug present
  if (slug) {
    router.push({
      path: slug,
    });
  }
});

import VueCountdown from '@chenfengyuan/vue-countdown';

Vue.component(VueCountdown.name, VueCountdown);

import VueMoment from 'vue-moment'

import moment from 'moment';
import 'moment/locale/de'

Vue.use(VueMoment, {
  moment
});


import vuetify from './plugins/vuetify'
import '@mdi/font/css/materialdesignicons.css'

import './stylesheets/main.scss'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(AppVue)
}).$mount('#app')
