<template>
  <div class="container-register">
    <v-snackbar v-model="showMessage">
      <span>{{ errorMessage }}</span>
    </v-snackbar>

    <Informations v-if="page === 0" />
    <Avatar v-if="page === 1 || page === 2" />

    <Success v-if="page === 3" />
  </div>
</template>

<script>
import Informations from "./Informations";
import Avatar from "./Avatar";
import Success from "./Success";

import UserService from "@/services/user.service";

// import { PushNotifications } from "@capacitor/push-notifications";

export default {
  name: "Register",
  data: () => ({
    errorMessage: "",
    showMessage: false,
  }),
  components: {
    Informations,
    Avatar,
    Success,
  },
  computed: {
    page: {
      get() {
        return this.$store.state.user.register_page;
      },
      set(value) {
        this.$store.dispatch("user/registerPage", value);
      },
    },
    form() {
      return this.$store.state.user.register_form;
    },
    avatar() {
      return this.$store.state.user.register_avatar;
    },
    token () {
      return this.$store.getters['user/pushToken']
    }
  },
  watch: {
    page: {
      handler: async function (newVal) {
        if (newVal === 2) {
          this.register();
        }
      },
    },
  },
  methods: {
    async register() {
      try {
        this.form.email = this.form.email.toLowerCase();

        const response = await UserService.register({...this.form, registration_token: this.token.value});

        if(this.avatar.file) {
          const responseAvatar = await UserService.registerAvatar(
            this.avatar.file,
            response.data.token
          );

          this.$store.dispatch("user/login", responseAvatar);
        } else {
          this.$store.dispatch("user/login", response);
        }


        this.page = 3;
      } catch (error) {
        this.page = 0;

        if (error.response.data.error[0]) {
          this.errorMessage = error.response.data.error[0];
          this.showMessage = true;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.container-register {
  height: 100vh;

  overflow-y: scroll;

  padding: 46px 50px 34px 24px;

  background-color: #27083a;

  .icon-back {
    width: 100%;

    display: flex;
  }
}
</style>