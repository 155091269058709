import instance from './base.service';

class ForumService {
  getAll() {
    return instance.get(`forum/threads.json`)
  }

  getCategory(id) {
    return instance.get(`/forum/threads/category/${id}.json`)
  }

  getCategories() {
    return instance.get(`/api/forum_categories.json`)
  }

  create(params) {
    return instance.post(`forum/threads.json`, params)
  }

  get(id) {
    return instance.get(`forum/threads/${id}.json`)
  }

  createComment(forum_thread_id, params) {
    return instance.post(`forum/threads/${forum_thread_id}/posts.json`, params)
  }

  solved(forum_thread_id, post_id) {
    return instance.put(`forum/threads/${forum_thread_id}/posts/${post_id}/solved.json`)
  }

  upvote(forum_thread_id, post_id) {
    return instance.post(`forum/threads/${forum_thread_id}/posts/${post_id}/upvote.json`)
  }

  downvote(forum_thread_id, post_id) {
    return instance.post(`forum/threads/${forum_thread_id}/posts/${post_id}/downvote.json`)
  }
}

export default new ForumService();
