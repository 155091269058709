<template>
  <div class="forum-new-container">
    <v-icon class="icon-back" color="ambient" large @click="$router.go(-1)">
      arrow_back
    </v-icon>

    <h1>Community</h1>

    <div class="simple_discussion">
      <div class="">
        <div class="card card-body">
          <h1>Start A Discussion</h1>

          <div class="forum_post">
            <form
              class="form"
              autocomplete="off"
              @submit.stop.prevent="handleSubmit"
            >
              <v-select
                background-color="#402D4F"
                color="#FFB0E2"
                outlined
                :items="categories"
                placeholder="Pick a category..."
                v-model="form.forum_category_id"
              />

              <v-text-field
                v-model="form.title"
                placeholder="Title for your post"
                background-color="#402D4F"
                color="#FFB0E2"
                outlined
              />

              <v-textarea
                v-model="form.forum_posts_attributes[0].body"
                placeholder="What do you need help with?"
                background-color="#402D4F"
                color="#FFB0E2"
                outlined
              />

              <v-btn color="#5573FF" dark type="submit" class="button-outlined"
                >Ask Your Question</v-btn
              >
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ForumService from "@/services/forum.service";

export default {
  name: "ThreadNew",
  data: function () {
    return {
      form: {
        forum_category_id: "",
        title: "",
        forum_posts_attributes: [
          {
            body: "",
          },
        ],
      },
      dataCategories: []
    };
  },
  computed: {
    categories () {
      return this.dataCategories.map((category) => {
        return {
          text: category.name,
          value: category.id,
        }
      })
    }
  },
  mounted () {
    this.initialize();
  },
  methods: {
    async initialize () {
      const response = await ForumService.getCategories();

      this.dataCategories = response.data;
    },
    async handleSubmit() {
      try {
        const response = await ForumService.create({
          forum_thread: this.form,
        });

        console.log(response);

        this.$router.go(-1);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
.forum-new-container {
  color: #ffb0e2;

  h1 {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  height: 100vh;
  overflow-y: scroll;

  margin-top: env(safe-area-inset-top);

  padding: 20px;

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;

    padding: 1.25rem;

    background: #27083a;

    .button-outlined {
      width: 100%;
    }
  }
  .form {
    width: 100%;

    display: flex;
    flex-direction: column;

    .v-text-field--outlined fieldset {
      border: none;
    }

    input, textarea, .v-select__selection {
      color: #ffb0e2;

      padding-left: 10px;

      font-size: 18px;
    }

    ::placeholder {
      color: #ffb0e2;
    }
  }
}
</style>
