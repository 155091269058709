<template>
  <div class="container-password">
    <div class="header">
      <v-icon class="icon-back" color="ambient" large @click="navigation(-1)">
        arrow_back
      </v-icon>

      <p class="title">Reset password</p>
    </div>

    <form class="form" autocomplete="off">
      <v-snackbar v-model="showMessage">
        <span>{{ errorMessage }}</span>
      </v-snackbar>

      <label>Password</label>
      <v-text-field
        v-model="form.password"
        placeholder="Password"
        color="#FFB0E2"
        outlined
        type="password"
        :error-messages="passwordErrors"
      />

      <label>Confirm password</label>
      <v-text-field
        v-model="form.password_confirmation"
        placeholder="Confirm password"
        color="#FFB0E2"
        outlined
        type="password"
        :error-messages="passwordConfirmationErrors"
      />
    </form>

    <v-btn color="#5573FF" dark @click="onSubmit()">DONE</v-btn>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
  import {
    required,
    minLength,
    sameAs
  } from 'vuelidate/lib/validators'

  import UserService from '@/services/user.service';

  export default {
    name: "ResetPassword",
    mixins: [validationMixin],
    data() {
      return {
        form: {
          password: "",
          password_confirmation: "",
        },
        errorMessage: "",
        showMessage: false,
      };
    },
    validations: {
      form: {
        password: {
          required,
          minLength: minLength(6)
        },
        password_confirmation: {
          required,
          sameAsPassword: sameAs('password')
        },
      }
    },
    computed: {
      token() {
        return this.$route.query.token;
      },
      passwordErrors () {
        const errors = []
        if (!this.$v.form.password.$dirty) return errors
        !this.$v.form.password.required && errors.push('Password is required')
        return errors
      },
      passwordConfirmationErrors () {
        const errors = []
        if (!this.$v.form.password_confirmation.$dirty) return errors
        !this.$v.form.password_confirmation.required && errors.push('Confirmation is required')
        !this.$v.form.password_confirmation.sameAsPassword && errors.push('Password confirmation is not compatible with the password')
        return errors
      },
    },
    methods: {
      async onSubmit() {
        this.$v.$touch();

        if (!this.$v.$invalid) {
          try {
            const response = await UserService.resetPassword({
              password: this.form.password,
              token: this.token,
            });

            this.errorMessage = response.data.status;
            this.showMessage = true;

            setTimeout(() => {
              this.$router.push("/login");
            }, 3000);
          } catch (error) {
            console.error(error);

            if(error.response.data.error[0]) {
              this.errorMessage = error.response.data.error[0];
              this.showMessage = true;
            }
          }
        }
      },
    },
  };
</script>

<style>

</style>