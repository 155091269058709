<template>
  <v-bottom-sheet v-model="sheet" class="bottom-sheet-location-map" style="overflow-y: scroll" persistent>
    <v-sheet :color="point.color" class="modal-location-map" dark>
      <div class="header">
        <div class="informations">
          <map-pin-icon size="1.5x" color="#27083A" />
          <p class="name">{{point.name}}</p>
          <div class="options">
            <x-icon color="#27083A" class="icon-close" @click="$emit('close')" />
          </div>
        </div>
      </div>

      <div class="body">
        <img v-if="point.image_url" :src="point.image_url" alt="" />

        <p class="title-list">Stage program:</p>

        <v-expansion-panels dark>
          <v-expansion-panel v-for="(item, i) in point.groups_schedules" :key="i">
            <v-expansion-panel-header>{{ weekDay(item) }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <Item
                v-for="schedule in item.schedules"
                :key="schedule.id"
                :program="schedule"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import { MapPinIcon, XIcon } from "vue-feather-icons";

import Item from "./Item";

export default {
  name: "ModalLocation",
  props: {
    point: {
      type: Object,
      // default: {}
    }
  },
  data: () => ({
    sheet: true,
    week_days: [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
  }),
  components: {
    MapPinIcon,
    XIcon,
    // CircleIcon,
    Item,
  },
  methods: {
    weekDay(item) {
      // , "Europe/Berlin"
      return this.$moment(item.date).locale('en').format('dddd')
    },
  }
};
</script>

<style lang="scss">
.v-bottom-sheet.v-dialog {
    overflow-y: scroll !important;
  }

.modal-location-map {
  .header {
    display: flex;
    justify-content: space-between;

    padding: 1rem 0.5rem;

    // height: 140px;

    border-bottom: 1px solid black;

    .informations {
      display: flex;
      grid-template-columns: auto auto auto;
      align-items: flex-start;
      column-gap: 0.5rem;
      width: 100%;

      svg {
        margin-top: 3px;
      }

      .name {
        // grid-column: 2 ;
        flex: 1;
        font-weight: 700;
        font-size: 2rem;
        line-height: 100%;

        color: #27083a;
      }

      .description {
        font-size: 18px;
        line-height: 27px;

        color: #27083a;
      }
    }

    .options {

      p {
        font-size: 12px;
        line-height: 14px;

        text-transform: uppercase;

        margin-bottom: 5px;

        color: #27083a;
      }
    }
  }

  img {
    width: 100%;

    // padding: 24px;
    padding: 2rem;
  }

  .title-list {
    font-weight: 700;
    font-size: 29px;
    line-height: 34px;

    padding: 0px 24px 24px 24px;

    color: #27083a;
  }

  .v-expansion-panels {
    border-top: 1px solid #27083a;
  }

  .v-expansion-panel {
    background-color: transparent !important;
    color: #27083a !important;

    border-radius: 0px;

    .v-expansion-panel-header {
      font-size: 18px;
      line-height: 27px;

      border-bottom: 1px solid #27083a;

      .mdi:before,
      .mdi-set {
        color: #27083a;
      }
    }
  }
}
</style>
