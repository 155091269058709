<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
    <path d="M0 12H24" :stroke="color" stroke-width="2"/>
    <path d="M24 12C17.3726 12 12 6.62742 12 0" :stroke="color" stroke-width="2" :transform="left ? 'translate(24 24) rotate(-180)' : ''"/>
    <path d="M12 24C12 17.3726 17.3726 12 24 12" :stroke="color" stroke-width="2" :transform="left ? 'translate(24 24) rotate(-180)' : ''"/>
  </svg>
</template>

<script>
export default {
  name: "icon-arrow",
  props: {
    color: {
      type: String,
      default: '#27083A'
    },
    left: {
      type: Boolean,
      default: false,
    }
  }

}
</script>

<style>

</style>