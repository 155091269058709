<template>
  <div class="container-health-and-safety">
    <div @click="$router.go(-1)">
      <icon-arrow color="#869cff" :left="true"/>
    </div>

    <p class="title">Terms of service</p>

    <p class="date">07.03.2021</p>

    <p class="text">
      This is the intro paragraph summarizing this content page, content.
    </p>

    <p class="subtext">
      This is the intro paragraph summarizing this content page, content.
    </p>

    <p class="subtext">
      This is the body text for the content. Today, the flooded grounds encapsulate an expansive 560 hectare lake +20 hectare peninsula.  The site displays five huge industrial excavators that create a museum, monument and modern event arena.

      Ferropolis is easily accessed by chartered bus, train, or car.
      Cruise the FAQ section for more details.
    </p>
  </div>
</template>

<script>
export default {
  name: "Terms",
};
</script>

<style lang="scss">
.container-health-and-safety {
  height: 100vh;

  overflow-y: scroll;

  background-color: #27083a;

  padding: 46px 50px 34px 24px;

  color: #869cff;

  .title {
    font-weight: 700;
    font-size: 47px;
    line-height: 56px;

    margin-top: 10px;
    margin-bottom: 30px;
  }

  .date {
    font-size: 16px;
    line-height: 24px;
  }

  .text {
    font-size: 29px;
    line-height: 34px;

    margin-bottom: 20px;
  }

  .subtext {
    font-size: 18px;
    line-height: 27px;

    margin-bottom: 20px;
  }
}
</style>