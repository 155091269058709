<template>
  <div class="container-map">
    <Header />

    <div id="map"></div>

    <!-- <map2023 id="map_svg" /> -->

    <map2024 id="map_svg" />

    <div class="footer">
      <div class="location" v-for="stage of filterStageTypes('stage')" :key="stage.id">
        <div class="point" :style="`background-color: ${stage.color}`" />
        <p>{{stage.name}}</p>
      </div>
    </div>

    <modal-location v-if="viewPoint" :point="viewPoint"  v-on:close="() => { viewPoint = null }"/>
  </div>
</template>

<script>
  import Header from "@/components/Header";

  import StagesService from "@/services/stages.service"

  import "leaflet/dist/leaflet.css";
  import leaflet from "leaflet";

  // import map2023 from "@/components/map2023";
  import map2024 from "@/components/map2024";

  import ModalLocation from "./ModalLocation/index";

  import "leaflet/dist/images/marker-shadow.png";

  export default {
    name: "Map",
    components: {
      // map2023,
      map2024,
      Header,
      ModalLocation,
    },
    data: () => ({
      stages: [],
      map: null,
      points: [],
      markers: [],
      viewPoint: null,
    }),
    computed: {
      mapsvg() {
        var svgElement = document.getElementById("map_svg");

        return svgElement;
      },
      bounds() {
        return leaflet.latLngBounds(
          leaflet.latLng(-35, 0),
          leaflet.latLng(358, 182)
        );
      }
    },
    async mounted() {
      await this.initialize();
    },
    methods: {
      async initialize() {
        this.handleMap();

        try {
          const response = await StagesService.getAll();

          this.markers = response.data.forEach(point => {
            var myIcon = new leaflet.divIcon({
              className: 'icon-marker',
              iconSize: [25, 25],
              iconAnchor: [10, 23],
              html: this.handleIcons(point.stage_type, point.color)
            });

            return leaflet.marker([point.latitude, point.longitude], {
              icon: myIcon
            }).addTo(this.map).on('click', this.getPoint);
          });

          this.stages = response.data;
        } catch (error) {
          console.error(error);
        }
      },
      handleMap() {
        this.map = leaflet.map("map", {
          center: this.bounds.getCenter(),
          maxBoundsViscosity: 0.5,
          zoom: 2,
          maxZoom: 6,
          minZoom: 2,
          // maxBounds: this.bounds,
          zoomControl: false,
          attributionControl: false,
          zoomDelta: 0.5
        });

        leaflet.svgOverlay(this.mapsvg, this.bounds).addTo(this.map);
        // this.map.setView(this.bounds.getCenter())

        const myCenter = this.bounds.getCenter()
        const spacingFromTop = 95.0
        // console.log(myCenter.lat, myCenter.lng)
        this.map.panTo({ lat: myCenter.lat - spacingFromTop, lng: myCenter.lng + 0.0 })
      },
      getPoint(e) {
        const point = this.stages.find((point) => point.latitude == e.latlng.lat && point.longitude == e.latlng.lng)

        if(point.stage_type == 'stage'){
          this.viewPoint = point;
        }
      },
      filterStageTypes(type) {
        return this.stages.filter(s => s.stage_type == type)
      },
      handleIcons(type, color) {
        if (type == "atm") return this.iconAtm()
        if (type == "bathroom") return this.iconBathroom()
        if (type == "camping") return this.iconCamping()
        if (type == "coffee") return this.iconCoffee()

        return this.iconPin(color)
      },
      iconPin(color) {
        return `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M35 16.6666C35 28.3333 20 38.3333 20 38.3333C20 38.3333 5 28.3333 5 16.6666C5 12.6884 6.58035 8.87307 9.3934 6.06002C12.2064 3.24698 16.0218 1.66663 20 1.66663C23.9782 1.66663 27.7936 3.24698 30.6066 6.06002C33.4196 8.87307 35 12.6884 35 16.6666Z" fill="${color}" stroke="#27083A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M20 21.6666C22.7614 21.6666 25 19.4281 25 16.6666C25 13.9052 22.7614 11.6666 20 11.6666C17.2386 11.6666 15 13.9052 15 16.6666C15 19.4281 17.2386 21.6666 20 21.6666Z" fill="white" stroke="#27083A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>`
      },
      iconAtm() {
        return `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M35 16.6666C35 28.3333 20 38.3333 20 38.3333C20 38.3333 5 28.3333 5 16.6666C5 12.6884 6.58035 8.87307 9.3934 6.06002C12.2064 3.24698 16.0218 1.66663 20 1.66663C23.9782 1.66663 27.7936 3.24698 30.6066 6.06002C33.4196 8.87307 35 12.6884 35 16.6666Z" fill="white" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9.21 20L11.21 13H13.11L15.1 20H13.99L13.53 18.35H10.79L10.33 20H9.21ZM11.06 17.35H13.26L12.24 13.67H12.08L11.06 17.35ZM17.0152 20V13.98H14.8652V13H20.2452V13.98H18.0952V20H17.0152ZM21.2241 20V13H23.2541L24.7841 19.28H24.9441L26.4741 13H28.5041V20H27.4541V13.76H27.2941L25.7741 20H23.9541L22.4341 13.76H22.2741V20H21.2241Z" fill="#074EE8"/>
                </svg>`
      },
      iconBathroom() {
        return `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M35 16.6666C35 28.3333 20 38.3333 20 38.3333C20 38.3333 5 28.3333 5 16.6666C5 12.6884 6.58035 8.87307 9.3934 6.06002C12.2064 3.24698 16.0218 1.66663 20 1.66663C23.9782 1.66663 27.7936 3.24698 30.6066 6.06002C33.4196 8.87307 35 12.6884 35 16.6666Z" fill="white" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <circle cx="25" cy="11" r="2.5" stroke="#074EE8"/>
                  <circle cx="14" cy="11" r="2.5" stroke="#074EE8"/>
                  <path d="M9.18945 19.6485L9.97422 14.839C10.0531 14.3552 10.471 14 10.9612 14H17.0388C17.529 14 17.9469 14.3552 18.0258 14.839L18.8106 19.6485C18.9098 20.257 18.4402 20.8095 17.8236 20.8095H16.2222V26C16.2222 26.5523 15.7745 27 15.2222 27H12.7778C12.2255 27 11.7778 26.5523 11.7778 26V20.8095H10.1764C9.55983 20.8095 9.09016 20.257 9.18945 19.6485Z" stroke="#074EE8"/>
                  <path d="M21 19.8095V15C21 14.4477 21.4477 14 22 14H28C28.5523 14 29 14.4477 29 15V19.8095C29 20.3618 28.5523 20.8095 28 20.8095H27.2857V26C27.2857 26.5523 26.838 27 26.2857 27H23.7143C23.162 27 22.7143 26.5523 22.7143 26V20.8095H22C21.4477 20.8095 21 20.3618 21 19.8095Z" stroke="#074EE8"/>
                </svg>`
      },
      iconCamping() {
        return `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M35 16.6666C35 28.3333 20 38.3333 20 38.3333C20 38.3333 5 28.3333 5 16.6666C5 12.6884 6.58035 8.87307 9.3934 6.06002C12.2064 3.24698 16.0218 1.66663 20 1.66663C23.9782 1.66663 27.7936 3.24698 30.6066 6.06002C33.4196 8.87307 35 12.6884 35 16.6666Z" fill="white" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M19.5 11L12.3549 23.5039C11.974 24.1705 12.4554 25 13.2232 25H17.5M19.5 11L26.6451 23.5039C27.026 24.1705 26.5446 25 25.7768 25H21M19.5 11L18 9H21L19.5 11ZM21 25V21C21 20.4477 20.5523 20 20 20H18.5C17.9477 20 17.5 20.4477 17.5 21V25M21 25H17.5" stroke="#5573FF" stroke-width="2"/>
                </svg>`
      },
      iconCoffee() {
        return `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M35 16.6666C35 28.3333 20 38.3333 20 38.3333C20 38.3333 5 28.3333 5 16.6666C5 12.6884 6.58035 8.87307 9.3934 6.06002C12.2064 3.24698 16.0218 1.66663 20 1.66663C23.9782 1.66663 27.7936 3.24698 30.6066 6.06002C33.4196 8.87307 35 12.6884 35 16.6666Z" fill="white" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><g clip-path="url(#clip0_353_19823)">
                  <path d="M25 14.6666H25.8333C26.7174 14.6666 27.5652 15.0178 28.1904 15.6429C28.8155 16.2681 29.1667 17.1159 29.1667 18C29.1667 18.884 28.8155 19.7319 28.1904 20.357C27.5652 20.9821 26.7174 21.3333 25.8333 21.3333H25" stroke="#5573FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M11.667 14.6666H25.0003V22.1666C25.0003 23.0507 24.6491 23.8985 24.024 24.5236C23.3989 25.1488 22.551 25.5 21.667 25.5H15.0003C14.1163 25.5 13.2684 25.1488 12.6433 24.5236C12.0182 23.8985 11.667 23.0507 11.667 22.1666V14.6666Z" stroke="#5573FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M15 8.83337V11.3334" stroke="#5573FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M18.333 8.83337V11.3334" stroke="#5573FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M21.667 8.83337V11.3334" stroke="#5573FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_353_19823"><rect width="20" height="20" fill="white" transform="translate(10 8)"/></clipPath></defs>
                </svg>`
      }
    }
  };
</script>

<style lang="scss">
.leaflet-map-pane svg {
  width: 100%;
  height: auto;
}
.container-map {
  height: calc(100vh - 106px);

  margin-top: 56px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  overflow-y: scroll;

  #map {
    width: 100%;
    height: 100%;

    z-index: 1;

    background-color: #270839;
  }

  .icon-marker {
    width: 100%;
    height: auto;

    .marker {
      width: 100%;
      height: 100%;

      opacity: 0.9;

      border-radius: 50%;
    }
  }

  .footer {
    width: 100%;
    background-color: #180523;

    // height: 100px;
    height: 220px;

    display: grid;
    grid-template-columns: auto auto;
    grid-auto-rows: auto;
    column-gap: 0px;
    row-gap: 3px;

    padding: 0px 1rem;
    padding-bottom: 2rem;
    // align-items: start;

    overflow-y: scroll;

    .location {
      display: flex;
      align-items: center;

      // padding: 5px;

      .point {
        width: 8px;
        height: 8px;

        margin-right: 4px;

        border-radius: 50%;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;

        text-transform: uppercase;

        color: #fff;
      }
    }
  }
}
</style>
