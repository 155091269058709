<template>
  <div class="container">
    <h1>Community</h1>

    <v-expansion-panels accordion dark v-if="false">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h5>
            <strong> Filters </strong>
          </h5>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div>
            <router-link to="/forum">All Threads</router-link>
          </div>
          <div>
            <router-link to="/forum/threads/answered">Answered</router-link>
          </div>
          <div>
            <router-link to="/forum/threads/unanswered">Unanswered</router-link>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <h5>Category</h5>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div>
            <router-link to="/forum">All</router-link>
          </div>
          <div>
            <router-link to="/forum?category=2">Feedback</router-link>
          </div>
          <div>
            <router-link to="/forum?category=1">General</router-link>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div class="forum-cats card card-body">
      <h4>categories</h4>
      <div>
        <router-link to="/forum">All</router-link>
      </div>

      <div v-for="category in categories" :key="`cat-${category.id}`">
        <router-link :to="`/forum?category=${category.id}`">{{ category.name }}</router-link>
      </div>
<!--
      <div>
        <router-link to="/forum?category=2">Feedback</router-link>
      </div>
      <div>
        <router-link to="/forum?category=1">General</router-link>
      </div> -->
    </div>

    <div class="simple_discussion">
      <div>
        <div class="card card-body">
          <v-btn
            class="button-outlined"
            color="#5573FF"
            outlined
            to="/forum/new"
            >Ask A Question</v-btn
          >
        </div>
      </div>

      <div>
        <div v-if="threads.length === 0">
          No posts here :)
        </div>

        <div class="card card-body" v-for="thread in threads" :key="thread.id">
          <div class="forum-thread">
            <h4>
              <router-link :to="`/forum/${thread.id}`">
                {{ thread.title }}
              </router-link>
            </h4>

            <div class="thread-details">
              @{{ thread.user.name }} on
              <span>#{{ thread.forum_category.slug }}</span>
              -
              {{ $moment(thread.created_at).fromNow() }}
              <span v-if="false && thread.solved" class="info-solved">
                <v-icon size="20px" color="#FFB0E2">done</v-icon>
                <!-- <span>Solved</span> -->
              </span>
            </div>

            <!-- <p class="text-muted">{{ thread.body }}</p> -->
            <router-link :to="`/forum/${thread.id}`" class="thread-posts-count">
              <v-icon size="20px" color="#FFB0E2">question_answer</v-icon>
              <span>{{ thread.forum_posts_count }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="whole-footer text-center">© 2024 WHOLE Festival GmbH</div> -->
  </div>
</template>

<script>
import ForumService from "@/services/forum.service";

export default {
  name: "Forum",
  data: function () {
    return {
      threads: [],
      categories: [],
    };
  },
  mounted() {
    this.initialize();
    this.getCategories();
  },
  watch: {
    '$route.query.category': function () {
      this.initialize();
    },
  },
  methods: {
    async initialize() {
      if (this.$route.query.category) {
        const response = await ForumService.getCategory(
          this.$route.query.category
        );

        this.threads = response.data;
      } else {
        const response = await ForumService.getAll();

        this.threads = response.data;
      }
    },

    async getCategories () {
      const response = await ForumService.getCategories();

      this.categories = response.data;
    }
  },
};
</script>

<style lang="scss" scoped>
.forum-cats {
  div {
    a {
      color: #5573ff;
    }
  }
}
.container {
  color: #ffb0e2;

  height: 100vh;
  overflow-y: scroll;

  margin-top: env(safe-area-inset-top);

  h1 {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  padding: 20px;
  padding-bottom: 94px;

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;

    padding: 1.25rem;
    margin-bottom: 20px;

    background: #27083a;

    .button-outlined {
      width: 100%;
    }

    a {
      color: #5573ff;
      text-decoration: none;
    }

    h5 {
      font-size: 1.25rem;
      margin-bottom: 0.5rem;
    }

    h6 {
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }

    hr {
      margin-top: 1rem;
      margin-bottom: 1rem;
      border: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .info-solved {
      background-color: #402d4f;

      border-radius: 10px;

      padding: 1px 10px;

      text-align: center;

      font-size: 15px;
    }
  }
}

.forum-thread {
  h4 {
    margin-bottom: 0.4rem;
    padding-right: 40px;
    // a {
    //   color: white;
    // }
  }
  position: relative;

  .thread-details {
    font-size: 0.85rem;
  }

  .thread-posts-count {
    color: #FFB0E2 !important;
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
