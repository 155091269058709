<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M14 2L18 6L7 17H3V13L14 2Z" stroke="#5573FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M3 22H21" stroke="#5573FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "icon-edit",
}
</script>

<style>

</style>