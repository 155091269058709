<template>
  <div class="container-location">
    <v-icon color="art_forest" large @click="$router.go(-1)">arrow_back</v-icon>

    <p class="title">The Location</p>

    <p class="subtitle">Ferropolis</p>

    <p class="text">
      Ferropolis is situated by the small town of Gräfenhainichen, about 140km
      southwest of Berlin. Its coal mining past, which ended in 1991, has
      recently been rectified by contemporary renovations that promote nature
      exploration.
    </p>

    <p class="subtext">
      Today, the flooded grounds encapsulate an expansive 560 hectare lake +20
      hectare peninsula. The site displays five huge industrial excavators that
      create a museum, monument and modern event arena.
    </p>

    <p class="subtext">
      Ferropolis is easily accessed by chartered bus, train, or car. Cruise the
      FAQ section for more details.
    </p>
  </div>
</template>

<script>
export default {
  name: "Location",
};
</script>

<style lang="scss">
.container-location {
  height: 100vh;

  overflow-y: scroll;

  background-color: #27083a;

  padding: 46px 50px 34px 24px;

  color: #00C97B;

  .title {
    font-weight: 700;
    font-size: 47px;
    line-height: 56px;

    margin-top: 10px;
    margin-bottom: 30px
  }

  .subtitle {
    font-weight: 700;
    font-size: 29px;
    line-height: 34px;

    margin-bottom: 20px;
  }

  .text {
    font-size: 29px;
    line-height: 34px;

    margin-bottom: 20px;
  }

  .subtext {
    font-size: 18px;
    line-height: 27px;

    margin-bottom: 20px;
  }
}
</style>