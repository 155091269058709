<template>
  <div class="container-welcome">
    <div class="ambient" v-if="show === 0">
      <img src="@/assets/welcome.svg" alt="" />

      <p class="icon">🌈</p>

      <p class="text">Welcome to Whole Queer Festival</p>
    </div>

    <div class="beach_floor" v-if="false">
      <p class="title">Stage is full?</p>

      <p class="icon">
        FULL
        <br />
        <v-icon small>radio_button_checked</v-icon>
        <v-icon small>radio_button_checked</v-icon>
        <v-icon small>radio_button_checked</v-icon>
      </p>

      <p class="text">
        To ensure everyones safety and health, we are upholding social
        distancing. This means that stages have a maximum capacity, which we
        will update through this app during the event.
      </p>
    </div>

    <div class="art_forest" v-if="show === 1">
      <p class="title">Need assistance?</p>

      <p class="icon">
        <v-icon color="beach_floor" x-large>phone_in_talk</v-icon>
      </p>

      <p class="text">
        We will provide a comprehensive medical and first aid service which
        includes first aid posts, as well as paramedics who will be looking
        after festival goers. Don’t hesitate to address them if you, or a
        friend, needs help.
      </p>
    </div>

    <div class="swimming" v-if="show === 2">
      <p class="title">Create your schedule</p>

      <p class="icon">
        <v-icon color="ambient" x-large>favorite_border</v-icon>
      </p>

      <p class="text">
        You can keep track of events by adding them from the Program to your own
        personal schedule, named Favs. You find it in the bottom navigation.
      </p>

      <p class="text" v-if="false">
        Note: Workshops requires you to sign up for them. For these events, the
        “Fav” icon is replaced with “Sign up”.
      </p>
    </div>

    <v-icon color="#402D4F" class="icon-next" large @click="navigation()"
      >arrow_forward</v-icon
    >
  </div>
</template>

<script>
export default {
  name: "Welcome",
  data: () => ({
    show: 0,
  }),
  methods: {
    navigation() {
      if(this.show + 1 > 2) {
        this.$router.push('/program');
      } else {
        this.show++;
      }
    }
  }
};
</script>

<style lang="scss">
.container-welcome {
  div {
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 40px;
  }

  .icon {
    margin: 50px;

    text-align: center;
  }

  .title {
    font-weight: 700;
    font-size: 29px;
    line-height: 35px;
  }

  .text {
    font-size: 18px;
    line-height: 27px;

    text-align: center;

    margin-bottom: 20px;
  }

  .icon-next {
    position: absolute !important;

    right: 40px;
    bottom: 40px;
  }
}
</style>