<template>
  <div class="container-login">
    <form class="form" autocomplete="off" @submit.prevent="onSubmit">
      <div class="header-form">
        <img src="@/assets/logo.svg" alt="WHOLE" />
      </div>

      <v-snackbar v-model="showMessage">
        <span>{{ errorMessage }}</span>
      </v-snackbar>

      <div class="inputs-form">
        <v-text-field
          v-model="form.email"
          placeholder="E-mail"
          background-color="#402D4F"
          color="#FFB0E2"
          outlined
          type="email"
          :error-messages="emailErrors"
        />

        <v-text-field
          v-model="form.password"
          placeholder="Password"
          background-color="#402D4F"
          color="#FFB0E2"
          outlined
          type="password"
          :error-messages="passwordErrors"
        />

        <v-btn color="#5573FF" dark type="submit">LOG IN</v-btn>

        <v-btn color="#5573FF" text to="/forgot-password"
          >I forgot my password</v-btn
        >
        <v-btn color="#5573FF" text to="/register">Create account</v-btn>
      </div>

    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";

import UserService from "@/services/user.service";

export default {
  name: "Login",
  mixins: [validationMixin],
  data: () => ({
    form: {
      email: "",
      password: "",
    },
    errorMessage: "",
    showMessage: false,
  }),
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(6),
      },
    },
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      !this.$v.form.email.email && errors.push("Invalid email");
      !this.$v.form.email.required && errors.push("E-mail is required");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.required && errors.push("Password is required");
      return errors;
    },
    token () {
      return this.$store.getters['user/pushToken']
    }
  },
  methods: {
    async onSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      try {
        this.form.email = this.form.email.toLowerCase();

        const response = await UserService.login({...this.form, registration_token: this.token.value});

        this.$store.dispatch("user/login", response);
        this.$router.push("/program_v3");
      } catch (error) {
        this.errorMessage = error.response.data.error;
        this.showMessage = true;
      }
    },
  },
};
</script>

<style lang="scss">
.container-login {
  .form {
  height: 100vh;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  padding: 100px 50px;

  background-color: #27083a;

  img {
    width: 180px;
  }

  .inputs-form {
    width: 100%;

    display: flex;
    flex-direction: column;

    .v-text-field--outlined fieldset {
      border: none;
    }

    input {
      color: #ffb0e2;

      padding-left: 10px;

      font-size: 18px;
    }

    ::placeholder {
      color: #ffb0e2;
    }

    button {
      font-size: 18px;

      height: 40px;
    }

    a {
      margin-top: 10px;
    }
  }
}}
</style>
