<template>
  <div class="container-data-policy">
    <v-icon color="ambient" large @click="$router.go(-1)">arrow_back</v-icon>

    <!-- --v-ambient-base -->

    <p class="title">DATA POLICY</p>

    <p>NUTZUNGSBEDINGUNGEN</p>

    <p>Dieser Vertrag regelt die vertragliche Beziehung zwischen WHOLE GmbH, Schönhauser Allee 167a in 10435 Berlin, vertreten durch die Geschäftsführer Raquel Fedato Pogoda, Giacomo Garavelloni, Cristian Pereira Phillips, Danilo Rosato (nachfolgend „Anbieter“) und Ihnen (nachfolgend „Nutzer“) unter Einbeziehung der Datenschutzrichtlinien des Anbieters für die Benutzung der App „Whole Festival“ (nachfolgend „Applikation“ oder „Vertragssoftware“), soweit zwischen dem Nutzer und dem Anbieter keine andere Regelung getroffen wurde.</p>


    <h4>1. VERTRAGSGEGENSTAND</h4>
    <p>
    Gegenstand dieses Vertrages ist die Nutzung der Applikation „Whole Festival“ über das Internet sowie über weitere Zugangsmöglichkeiten über weitere Plattformen (iPhone Apps, Android Apps, etc. im Objektcode und die Einräumung der nachfolgend benannten Nutzungsrechte. Die Applikation wird durch den Anbieter kostenlos im Internet angeboten.</p>

    <h4>2. VERTRAGSSCHLUSS</h4>
    <p>2.1. Durch die Annahme dieses Vertrages und/oder die Benutzung der Applikation erklären Sie sich mit den Regelungen dieses Vertrages einverstanden. Der Vertrag kommt ausschließlich zwischen dem Anbieter und dem Nutzer zustande. Hersteller von Betriebssystemen auf mobilen Endgeräten oder sonstige Betreiber von Portalen oder Hersteller von mobilen Endgeräten sind nicht Partner dieses Vertrages.</p>
    <p>2.2. Soweit im Rahmen der Applikation weitere kostenpflichtige Leistungen angeboten werden, so gelten insoweit die Allgemeinen Geschäftsbedingungen des Anbieters. Die Nutzung der Applikation ist für den Nutzer kostenlos.</p>

    <h4>3. EINRÄUMUNG VON NUTZUNGSRECHTEN</h4>
    <p>
    3.1. Der Anbieter räumt dem Nutzer das nicht ausschließliche, zeitlich unbeschränkte Recht zur Nutzung der Applikation ein. Die Applikation darf nur durch den Nutzer auf dem in seinem Eigentum stehende mobile Endgerät genutzt werden. Die zulässige Nutzung umfasst das Laden in den Arbeitsspeicher sowie den bestimmungsgemäßen Gebrauch durch den Nutzer. In keinem Fall hat der Nutzer das Recht, die Applikation zu vermieten oder in sonstiger Weise an Dritte zu lizenzieren, sie drahtgebunden oder drahtlos öffentlich wiederzugeben oder zugänglich zu machen oder sie Dritten entgeltlich oder unentgeltlich zur Verfügung zu stellen, z.B. im Wege des Application Service Providing oder als „Software as a Service“. </p>
    <p>3.2. Der Nutzer ist berechtigt, eine Sicherungskopie zu erstellen, wenn dies zur Sicherung der künftigen Nutzung erforderlich ist. Der Nutzer wird auf der erstellten Sicherungskopie den Vermerk „Sicherungskopie“ sowie einen Urheberrechtsvermerk des Anbieters sichtbar anbringen.</p>
    <p>3.3. Nutzt der Kunde die Vertragssoftware in einem Umfang, der die erworbenen Nutzungsrechte qualitativ (im Hinblick auf die Art der gestatteten Nutzung) oder quantitativ (im Hinblick auf die Anzahl der erworbenen Lizenzen) überschreitet, so wird er unverzüglich die zur erlaubten Nutzung notwendigen Nutzungsrechte erwerben. Unterlässt er dies, so wird der Anbieter die ihm zustehenden Rechte geltend machen. </p>
    <p>3.4. Urhebervermerke, Seriennummern sowie sonstige der Programmidentifikation dienende Merkmale dürfen nicht von der Applikation entfernt oder verändert werden.</p>

    <h4>4. SICHERUNGSMASSNAHMEN UND RECHTE DRITTER</h4>
    <p>4.1. Der Nutzer wird die Applikation sowie die Zugangsdaten für den Onlinezugriff durch geeignete Maßnahmen vor dem Zugriff durch unbefugte Dritte sichern. Insbesondere sind sämtliche Kopien der Vertragssoftware sowie die Zugangsdaten an einem geschützten Ort zu verwahren. </p>
    <p>4.2. Der Nutzer garantiert, dass er zur Verwendung der von ihm mit der Applikation genutzten Daten berechtigt ist und diese weder ganz noch teilweise unberechtigt erlangt wurden. </p>
    <p>4.3. Der Kunde hat sicherzustellen, dass die gelieferten Daten, Grafiken, Informationen etc. keine Inhalte beinhalten, die verfassungsfeindlich, sittenwidrig, gewaltverherrlichend sind, strafrechtliche Normen verletzen, diskriminierend sind oder geeignet, andere aufgrund ihrer weltanschaulichen, politischen oder religiösen Einstellung abzuwerten. Darüber hinaus dürfen keine Inhalte geliefert werden, die geeignet sind, Rechte Dritter, insbesondere gewerbliche Schutzrechte und Urheberrechte zu verletzen.</p>
    <p>4.4. Der Nutzer stellt den Anbieter sowie die Rechtsnachfolger des Anbieters von sämtlichen Ansprüchen Dritter, die gegen den Anbieter oder seine Rechtsnachfolger erhoben werden sollten, frei. Ihm bekannt werdende Beeinträchtigungen der vertragsgegenständlichen Rechte hat der Nutzer dem Anbieter unverzüglich mitzuteilen. Der Anbieter ist berechtigt, selbst geeignete Maßnahmen zur Abwehr von Ansprüchen Dritter oder zur Verfolgung ihrer Rechte vorzunehmen. Eigene Maßnahmen des Nutzers hat dieser im Vorwege mit dem Anbieter abzustimmen. Die Freistellung beinhaltet auch einen Vorschuss oder Ersatz der dem Anbieter durch die notwendige Rechtsverfolgung/-verteidigung entstehenden bzw. entstanden Kosten, soweit diese nicht von Dritten zu erstatten sind. </p>

    <h4>5. DATENSCHUTZ</h4>
    <p>Mit Nutzung der jeweiligen Funktion erklärt sich der Nutzer durch Eingabe der von ihm eingegebenen Daten bereit, dass der Anbieter zur Bereitstellung der Funktionen der Applikation die erforderlichen personenbezogenen Daten des Nutzers sowie die von ihm über Dritte eingestellten personenbezogenen Daten Daten unter Einhaltung der gesetzlichen Bestimmungen des Bundesdatenschutzgesetzes (BDSG) sowie der Datenschutzgrundverordnung (DSGVO) speichert, verarbeitet und nutzt. Details regelt die Datenschutzbestimmung des Anbieters. </p>

    <h4>6. GEWÄHRLEISTUNG UND HAFTUNG</h4>
    <p>6.1. Das Laden und Nutzen der Applikation erfolgt auf Gefahr des Nutzers. Dem Nutzer steht kein Anspruch auf Aufrechterhaltung oder Herbeiführung eines bestimmten Zustandes und/oder einer bestimmten Funktion und/oder Zusatzleistungen zu. Insbesondere akzeptiert der Nutzer, dass eine Applikation – wie jede Software – nicht fehlerfrei sein kann.</p>
    <p>6.2. Der Anbieter übernimmt, soweit gesetzlich zulässig, keine Gewähr für die ungestörte und/oder fehlerfrei Nutzung der Applikation oder der Daten oder dass Mängel korrigiert werden. Insbesondere ist eine Gewährleistung für die Benutzung oder die Ergebnisse der Benutzung der Applikation oder Daten im Sinne der Richtigkeit, Vollständigkeit, Genauigkeit, Verlässlichkeit durch den Anbieter ausgeschlossen.</p>
    <p>6.3. Der Nutzer erklärt sich ausdrücklich damit einverstanden, dass der Anbieter nicht für direkte, indirekte, Neben-, besondere, Folgeschäden, verschärften Schadensersatz oder alle sonstigen Schäden haftet, einschließlich entgangenen Gewinns, Goodwill, Benutzung, Daten- oder sonstige immaterielle Verluste, die aus der Nutzung oder Unfähigkeit der Nutzung der Applikation oder Daten oder der Beschaffung von Ersatzwaren und/oder Dienstleistungen, die aus der Benutzung der Applikation oder der Daten oder eines darin enthaltenen Mangels entstehen, soweit dies gesetzlich zulässig ist.</p>
    <p>6.4. Eine Haftung des Anbieters für die Verletzung von Leben, Leib oder Gesundheit sowie den gesetzlichen Regelungen des Produkthaftungsgesetzes und für durch den Anbieter übernommene Garantien bleibt unberührt.</p>

    <h4>7. VERTRAGSDAUER UND KÜNDIGUNG</h4>
    <p>7.1. Der Vertrag zwischen dem Nutzer und dem Anbieter wird auf unbestimmte Zeit geschlossen und kann durch den Nutzer jederzeit ordentlich gekündigt werden. Die Kündigung bedarf der Erklärung der Kündigung in Textform (z.B. E-Mail) an die im Impressum angegebene Adresse. Mit Wirksamwerden der Kündigung wird Anbieter den Account des Nutzers sperren.</p>
    <p>7.2. Das Recht zur außerordentlichen fristlosen Kündigung bleibt unberührt. Ein Recht zur außerordentlichen Kündigung liegt für Anbieter insbesondere dann vor, wenn</p>
    <p>7.2.1. der Nutzer unvollständige und unwahre Angaben gegenüber Anbieter gemacht haben,</p>
    <p>7.2.2. das Angebot des Anbieters durch den Nutzer missbräuchlich und nicht in Übereinstimmung mit diesen Bestimmungen genutzt wird.</p>
    <p>7.3. Auf Anforderung des Kunden stellt der Anbieter dem Kunden die von ihm eingebrachten Daten in einem üblichen maschinenlesbaren Dateiformat (JSON Datei) zur Verfügung. Ein Anspruch des Kunden auf weitere Daten, insbesondere Druckdaten, wird ausgeschlossen.</p>

    <h4>8. VERTRAGSWIDRIGE NUTZUNG</h4>
    <p>Der Anbieter ist berechtigt, bei rechtswidrigem Verstoß des Nutzers gegen eine der in diesem Vertrag festgelegten wesentlichen Pflichten den Zugang zur Vertragssoftware und zu dessen Daten zu sperren. Der Zugang wird erst dann wiederhergestellt, wenn der Verstoß gegen die betroffene wesentliche Pflicht dauerhaft beseitigt bzw. die Wiederholungsgefahr durch Abgabe einer angemessenen strafbewehrten Unterlassungserklärung gegenüber dem Anbieter sichergestellt ist. </p>

    <h4>9. AKTUALISIERUNG DER SOFTWARE</h4>
    <p>Der Anbieter behält sich das Recht vor, die in der Vertragssoftware enthaltenen Leistungen und/oder Funktionalitäten zu erweitern, zu verringern, zu verändern oder sonstige Weiterentwicklungen an der Vertragssoftware vorzunehmen. Im Falle der Verringerung der Leistungen und/oder Funktionalitäten an der Vertragssoftware steht dem Nutzer ein außerordentliches Kündigungsrecht dieses Vertrages zu, soweit das Gleichgewicht von Leistung und Gegenleistung mehr als unwesentlich beeinträchtigt ist und die Parteien keine sonstige Regelung zur Fortsetzung dieses Vertrages vereinbaren können.</p>

    <h4>10. HÖHERE GEWALT</h4>
    <p>10.1. Der Anbieter ist von der Verpflichtung zur Leistung aus diesem Vertrag befreit, wenn und soweit die Nichterfüllung von Leistungen auf das Eintreten von Umständen höherer Gewalt nach Vertragsabschluss zurückzuführen ist.</p>
    <p>10.2.Als Umstände höherer Gewalt gelten zum Beispiel Krieg, Streiks, Unruhen, Enteignungen, kardinale Rechtsänderungen, Sturm, Überschwemmungen und sonstige Naturkatastrophen sowie sonstige von Anbieter nicht zu vertretende Umstände, insbesondere Wassereinbrüche, Stromausfälle und Unterbrechungen oder Zerstörung datenführender Leitungen.</p>
    <p>10.3. Jede Vertragspartei hat die andere Vertragspartei über den Eintritt eines Falles von höherer Gewalt unverzüglich und in Textform in Kenntnis zu setzen.</p>

    <h4>11. SCHLUSSBESTIMMUNGEN</h4>
    <p>11.1. Ergänzungen oder Änderungen dieses Vertrages bedürfen der Schriftform. Dies gilt auch für diese Schriftformklausel.</p>
    <p>11.2. Erfüllungsort und Gerichtsstand ist, soweit gesetzlich zulässig, für Leistungen und Zahlungen sowie sämtliche zwischen den Parteien sich ergebende Streitigkeiten, der Sitz des Anbieters. Bei Endverbrauchern sind Erfüllungsort und Gerichtsstand der Sitz des Nutzers. Hat der private Endverbraucher keinen Wohnsitz innerhalb der Europäischen Union, so ist der Gerichtsstand am Sitz von Anbieter. Im Verkehr mit Endverbrauchern innerhalb der Europäischen Union kann auch das Recht am Wohnsitz des Endverbrauchers anwendbar sein, sofern es sich zwingend um verbraucherrechtliche Bestimmungen handelt.</p>
    <p>11.3. Soweit eine oder mehrere der Klauseln dieses Vertrages unwirksam sind oder im Laufe der Zeit werden sollten, bleibt die Wirksamkeit des übrigen Vertrages davon unberührt. An die Stelle der unwirksamen Klauseln treten die gesetzlichen Regelungen. Die gesetzlichen Regelungen gelten auch im Falle einer Regelungslücke.</p>
    <p>11.4. Die Vertragsparteien werden sich gegenseitig umgehend über alle Umstände, die für die Durchführung dieses Vertrages von Bedeutung sein könnten, unterrichten.</p>
    <p>11.5. Anfallende Internet-Verbindungskosten (Telekommunikationskosten) trägt der Nutzer selbst.</p>
    <p>11.6. Der Anbieter behält sich das Recht vor, diese Nutzungsbedingungen zu ändern, soweit dies notwendig erscheint, z.B. aufgrund einer geänderten Gesetzeslage, und der Vertragspartner hierdurch nicht wider Treu und Glauben benachteiligt wird. Anbieter wird den Nutzer im Falle der Änderung dieser Allgemeinen Geschäftsbedingungen schriftlich oder in Textform informieren. Der Nutzer kann der Änderung der Nutzungsbedingungen in diesem Fall innerhalb einer dann mitzuteilenden angemessenen Frist widersprechen. Widerspricht der Nutzer der Änderung der Nutzungsbedingungen nicht oder nicht rechtzeitig, so werden diese nach Ablauf der angemessenen Frist Bestandteil des mit dem Nutzer geschlossenen Vertrages.</p>

    <p>***<br>
    Stand: August 2021</p>

    <br>
    <br>
    <br>
    <br>
    <br>


    <h2 class="d-flex justify-content-center">DATENSCHUTZERKLÄRUNG</h2>

    <h3>Name und Anschrift des Unternehmens</h3>

    <p>
      Der Verantwortliche im Sinne der Datenschutzgrundverordnung (nachfolgend
      „DSGVO“) und anderer nationaler Datenschutzgesetze der Mitgliedsstaaten
      sowie sonstiger datenschutzrechtlicher Bestimmungen ist:
    </p>

    <p>
      WHOLE GmbH, Schönhauser Allee 167a in 10435 Berlin, vertreten durch die
      Geschäftsführer Christian Phillips, Danilo Rosato, Giacomo Garavellonie,
      Giovanni Turco, Jacob Meehan und Rauel Fedato <br />
      Tel.: +49 30 83217850 <br />
      E-Mail: contact@wholefestival.com
    </p>

    <h3>Allgemeine Datenverarbeitung</h3>

    <h4>Umfang und Erlaubnis der Verarbeitung personenbezogener Daten</h4>

    <p>
      Wir erheben und verwenden personenbezogene Daten unserer Nutzer
      grundsätzlich nur, soweit dies zur Bereitstellung der Funktionsfähigkeit
      unserer Internetseite sowie unserer Inhalte und Leistungen erforderlich
      ist. Die Erhebung und Verwendung personenbezogener Daten unserer Nutzer
      erfolgt regelmäßig nur nach Einwilligung des Nutzers
    </p>

    <p>
      Eine Ausnahme gilt in solchen Fällen, in denen eine vorherige Einholung
      einer Einwilligung aus tatsächlichen Gründen nicht möglich ist und die
      Verarbeitung der Daten durch gesetzliche Vorschriften gestattet ist.
    </p>

    <p>
      Rechtsgrundlage für Verarbeitungsvorgänge personenbezogener Daten bei
      vorheriger Einholung einer Einwilligung der betroffenen Person, ist Art. 6
      Abs. 1 lit. a DSGVO. Für die Verarbeitung personenbezogener Daten, die zur
      Erfüllung eines Vertrages oder zur Durchführung vorvertraglicher Maßnahmen
      erforderlich sind, ist Rechtsgrundlage Art. 6 Abs. 1 lit. b DSGVO. Bei der
      Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen
      Verpflichtung ist Rechtsgrundlage Art. 6 Abs. 1 lit. c DSGVO. Für den
      Fall, dass lebenswichtige Interessen der betroffenen Person oder einer
      anderen natürlichen Person eine Verarbeitung personenbezogener Daten
      erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage.
      Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres
      Unternehmens oder eines Dritten erforderlich und überwiegen diese
      Interessen dem Interesse des Betroffenen unter Berücksichtigung der
      Grundrechte und Grundfreiheiten des Betroffenen ist Art. 6 Abs. 1 lit. f
      DSGVO die Rechtsgrundlage für die Verarbeitung der Daten.
    </p>

    <h4>Datenlöschung und Speicherdauer</h4>

    <p>
      Die personenbezogenen Daten der betroffenen Person werden gelöscht oder
      gesperrt, sobald der Zweck der Speicherung entfällt. Eine Speicherung kann
      darüber hinaus dann erfolgen, wenn dies wir aufgrund einer gesetzlichen
      Vorschrift zur Verarbeitung der Daten vorgesehen ist. Eine Sperrung oder
      Löschung der Daten erfolgt in diesem Fall, wenn die gesetzlich
      vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine
      Erforderlichkeit zur weiteren Speicherung der Daten für einen
      Vertragsabschluss oder eine Vertragserfüllung besteht.
    </p>

    <h3>Datenverarbeitung durch Nutzung unserer Internetseite</h3>

    <h4>Besuch unserer Internetseite</h4>

    <p>
      Beim Aufrufen unserer Internetseite werden durch den auf Ihrem Endgerät
      zum Einsatz kommenden Browser automatisch Informationen an den Server
      unserer Website gesendet. Diese Informationen werden temporär in einem
      sog. Logfile gespeichert. Folgende Informationen werden dabei ohne Ihr
      Zutun erfasst und bis zur automatisierten Löschung gespeichert: IP-Adresse
      des anfragenden Rechners, Datum und Uhrzeit des Zugriffs, Name und URL der
      abgerufenen Datei, Website, von der aus der Zugriff erfolgt
      (Referrer-URL), verwendeter Browser und ggf. das Betriebssystem Ihres
      Rechners sowie der Name Ihres Access-Providers.
    </p>

    <p>
      Die genannten Daten werden durch uns zu den Zwecken Gewährleistung eines
      reibungslosen Verbindungsaufbaus der Website, Gewährleistung einer
      komfortablen Nutzung unserer Website, Auswertung der Systemsicherheit und
      -stabilität sowie zu weiteren administrativen Zwecken verarbeitet.
    </p>

    <p>
      Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit.
      f DSGVO. Unser berechtigtes Interesse folgt aus oben aufgelisteten Zwecken
      zur Datenerhebung. In keinem Fall verwenden wir die erhobenen Daten zu dem
      Zweck, Rückschlüsse auf Ihre Person zu ziehen.
    </p>

    <h4>Nutzung Online-Shop Funktionen</h4>

    <p>
      Wir verarbeiten die Daten unserer Kunden im Rahmen der Bestellvorgänge in
      unserem Onlineshop, um ihnen die Auswahl und die Bestellung der gewählten
      Produkte und Leistungen, sowie deren Bezahlung und Zustellung, bzw.
      Ausführung zu ermöglichen.
    </p>

    <p>
      Zu den verarbeiteten Daten gehören Bestandsdaten, Kommunikationsdaten,
      Vertragsdaten, Zahlungsdaten und zu den von der Verarbeitung betroffenen
      Personen gehören unsere Kunden, Interessenten und sonstige
      Geschäftspartner. Die Verarbeitung erfolgt zum Zweck der Erbringung von
      Vertragsleistungen im Rahmen des Betriebs eines Onlineshops, Abrechnung,
      Auslieferung und der Kundenservices. Hierbei setzen wir Session Cookies
      für die Speicherung des Warenkorb-Inhalts und permanente Cookies für die
      Speicherung des Login-Status ein.
    </p>

    <p>
      Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. b
      (Durchführung Bestellvorgänge) und c (Gesetzlich erforderliche
      Archivierung) DSGVO. Dabei sind die als erforderlich gekennzeichneten
      Angaben zur Begründung und Erfüllung des Vertrages erforderlich. Die Daten
      offenbaren wir gegenüber Dritten nur im Rahmen der Auslieferung, Zahlung
      oder im Rahmen der gesetzlichen Erlaubnisse und Pflichten gegenüber
      Rechtsberatern und Behörden. Die Daten werden in Drittländern nur dann
      verarbeitet, wenn dies zur Vertragserfüllung erforderlich ist (z.B. auf
      Kundenwunsch bei Auslieferung oder Zahlung).
    </p>

    <p>
      Nutzer können optional ein Nutzerkonto anlegen, indem sie insbesondere
      ihre Bestellungen einsehen können. Im Rahmen der Registrierung, werden die
      erforderlichen Pflichtangaben den Nutzern mitgeteilt. Die Nutzerkonten
      sind nicht öffentlich und können von Suchmaschinen nicht indexiert werden.
      Wenn Nutzer ihr Nutzerkonto gekündigt haben, werden deren Daten im
      Hinblick auf das Nutzerkonto gelöscht, vorbehaltlich deren Aufbewahrung
      ist aus handels- oder steuerrechtlichen Gründen entspr. Art. 6 Abs. 1 lit.
      c DSGVO notwendig. Angaben im Kundenkonto verbleiben bis zu dessen
      Löschung mit anschließender Archivierung im Fall einer rechtlichen
      Verpflichtung. Es obliegt den Nutzern, ihre Daten bei erfolgter Kündigung
      vor dem Vertragsende zu sichern.
    </p>

    <p>
      Im Rahmen der Registrierung und erneuter Anmeldungen sowie Inanspruchnahme
      unserer Onlinedienste, speichern wir die IP-Adresse und den Zeitpunkt der
      jeweiligen Nutzerhandlung. Die Speicherung erfolgt auf Grundlage unserer
      berechtigten Interessen, als auch der Nutzer an Schutz vor Missbrauch und
      sonstiger unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte
      erfolgt grundsätzlich nicht, außer sie ist zur Verfolgung unserer
      Ansprüche erforderlich oder es besteht hierzu eine gesetzliche
      Verpflichtung gem. Art. 6 Abs. 1 lit. c DSGVO.
    </p>

    <h4>Nutzung unseres Kontaktformulars</h4>

    <p>
      Auf unserer Internetseite ist ein Kontaktformular vorhanden, welches für
      die elektronische Kontaktaufnahme genutzt werden kann. Nimmt ein Nutzer
      diese Möglichkeit wahr, so werden die in das Kontaktformular eingegeben
      Daten sowie die IP Adresse, das Datum und die Uhrzeit an uns übermittelt
      und gespeichert. Für die Verarbeitung der Daten wird im Rahmen des
      Absendevorgangs Ihre Einwilligung eingeholt und auf diese
      Datenschutzerklärung verwiesen. Rechtsgrundlage für die Verarbeitung der
      Daten ist in diesem Fall Art. 6 Abs. 1 lit. a DSGVO.
    </p>

    <p>
      Alternativ ist eine Kontaktaufnahme über die bereitgestellte
      E-Mail-Adresse möglich. In diesem Fall werden die mit der E-Mail
      übermittelten personenbezogenen Daten des Nutzers gespeichert. Es erfolgt
      in diesem Zusammenhang keine Weitergabe der Daten an Dritte.
      Rechtsgrundlage für die Verarbeitung dieser Daten ist Art. 6 Abs. 1 lit. f
      DSGVO. Zielt der E-Mail-Kontakt auf den Abschluss eines Vertrages ab, so
      ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b
      DSGVO.
    </p>

    <p>
      Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit uns erfolgt nach
      Art. 6 Abs. 1 S. 1 lit. a DSGVO auf Grundlage Ihrer freiwillig erteilten
      Einwilligung. Die für die Benutzung des Kontaktformulars von uns erhobenen
      personenbezogenen Daten werden nach Erledigung der von Ihnen gestellten
      Anfrage automatisch gelöscht.
    </p>

    <h4>Registrierung</h4>

    <p>
      Sie haben die Möglichkeit, sich auf unserer Internetseite unter Angabe von
      personenbezogenen Daten zu registrieren. Welche personenbezogenen Daten
      dabei an uns übermittelt werden, ergibt sich aus der jeweiligen
      Eingabemaske, die für die Registrierung verwendet wird. Die von Ihnen
      eingegebenen personenbezogenen Daten werden ausschließlich für die interne
      Verwendung für eigene Zwecke verarbeitet. Wir können die Weitergabe an
      einen oder mehrere Auftragsverarbeiter veranlassen, der Ihre
      personenbezogenen Daten ebenfalls ausschließlich für eine interne
      Verwendung, die uns zuzurechnen ist, nutzt.
    </p>

    <p>
      Durch eine Registrierung auf unserer Internetseite wird ferner die vom
      Internet-Service-Provider (ISP) der betroffenen Person vergebene
      IPAdresse, das Datum sowie die Uhrzeit der Registrierung gespeichert. Die
      Speicherung dieser Daten erfolgt vor dem Hintergrund, dass nur so der
      Missbrauch unserer Dienste verhindert werden kann, und diese Daten im
      Bedarfsfall ermöglichen, begangene Straftaten aufzuklären. Insofern ist
      die Speicherung dieser Daten zur Absicherung erforderlich. Eine Weitergabe
      dieser Daten an Dritte erfolgt grundsätzlich nicht, sofern keine
      gesetzliche Pflicht zur Weitergabe besteht oder die Weitergabe der
      Strafverfolgung dient.
    </p>

    <p>
      Ihre Registrierung unter freiwilliger Angabe personenbezogener Daten dient
      dazu, Ihnen Inhalte oder Leistungen anzubieten, die aufgrund der Natur der
      Sache nur registrierten Benutzern angeboten werden können. Registrierten
      Personen steht die Möglichkeit frei, die bei der Registrierung angegebenen
      personenbezogenen Daten jederzeit abzuändern oder vollständig aus unserem
      Datenbestand löschen zu lassen.
    </p>

    <p>
      Wir erteilen jeder betroffenen Person jederzeit auf Anfrage Auskunft
      darüber, welche personenbezogenen Daten über die betroffene Person
      gespeichert sind. Ferner berichtigen oder löschen wir personenbezogene
      Daten auf Wunsch oder Hinweis der betroffenen Person, soweit dem keine
      gesetzlichen Aufbewahrungspflichten entgegenstehen.
    </p>

    <h4>Newsletter</h4>

    <p>
      Wir bieten Ihnen auf unserer Internetseite die Möglichkeit, unseren
      Newsletter zu abonnieren. Mit diesem Newsletter informieren wir in
      regelmäßigen Abständen über unsere Angebote. Um unseren Newsletter
      empfangen zu können, benötigen Sie eine gültige E-Mail-Adresse. Die von
      Ihnen eingetragene E-Mail-Adresse werden wir dahingehend überprüfen, ob
      Sie tatsächlich der Inhaber der angegebenen E-Mail-Adresse sind bzw. deren
      Inhaber den Empfang des Newsletters autorisiert ist.
    </p>

    <p>
      Mit Ihrer Anmeldung zu unserem Newsletter werden wir Ihre IP-Adresse und
      das Datum sowie die Uhrzeit Ihrer Anmeldung speichern. Dies dient in dem
      Fall, dass ein Dritter Ihre E-Mail-Adresse missbraucht und ohne Ihr Wissen
      unseren Newsletter abonniert, als Absicherung unsererseits. Weitere Daten
      werden unsererseits nicht erhoben. Die so erhobenen Daten werden
      ausschließlich für den Bezug unseres Newsletters verwendet. Eine
      Weitergabe an Dritte erfolgt nicht. Ein Abgleich der so erhobenen Daten
      mit Daten, die möglicherweise durch andere Komponenten unserer Seite
      erhoben werden, erfolgt ebenfalls nicht. Das Abonnement dieses Newsletters
      können Sie jederzeit kündigen. Einzelheiten hierzu können Sie der
      Bestätigungsmail sowie jedem einzelnen Newsletter entnehmen.
    </p>

    <h4>Newsletter-Tracking</h4>

    <p>
      Unsere Newsletter enthalten sogenannte Zählpixel. Ein Zählpixel ist eine
      Miniaturgrafik, die in solche E-Mails eingebettet wird, welche im
      HTML-Format versendet werden, um eine Logdatei-Aufzeichnung und eine
      Logdatei-Analyse zu ermöglichen. Dadurch kann eine statistische Auswertung
      des Erfolges oder Misserfolges von Online-Marketing-Kampagnen durchgeführt
      werden. Anhand des eingebetteten Zählpixels können wir erkennen, ob und
      wann eine E-Mail von einer betroffenen Person geöffnet wurde und welche in
      der E-Mail befindlichen Links von der betroffenen Person aufgerufen
      wurden.
    </p>

    <p>
      Solche über die in den Newslettern enthaltenen Zählpixel erhobenen
      personenbezogenen Daten, werden von uns gespeichert und ausgewertet, um
      den Newsletterversand zu optimieren und den Inhalt zukünftiger Newsletter
      noch besser den Interessen der betroffenen Person anzupassen. Diese
      personenbezogenen Daten werden nicht an Dritte weitergegeben. Betroffene
      Personen sind jederzeit berechtigt, die diesbezügliche gesonderte, über
      das Double-Opt-In-Verfahren abgegebene Einwilligungserklärung zu
      widerrufen. Nach einem Widerruf werden diese personenbezogenen Daten von
      dem für die Verarbeitung Verantwortlichen gelöscht. Eine Abmeldung vom
      Erhalt unseres Newsletters legen wir immer als Widerruf aus.
    </p>

    <h4>Weitergabe von Daten</h4>

    <p>
      Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im
      Folgenden aufgeführten Zwecken findet nicht statt. Wir geben Ihre
      persönlichen Daten nur an Dritte weiter, wenn Sie Ihre nach Art. 6 Abs. 1
      S. 1 lit. a DSGVO ausdrückliche Einwilligung dazu erteilt haben, die
      Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung,
      Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und kein
      Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges
      Interesse an der Nichtweitergabe Ihrer Daten haben, für den Fall, dass für
      die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche
      Verpflichtung besteht, sowie dies gesetzlich zulässig und nach Art. 6 Abs.
      1 S. 1 lit. b DSGVO für die Abwicklung von Vertragsverhältnissen mit Ihnen
      erforderlich ist.
    </p>

    <h4>Verwendung von Cookies</h4>

    <p>
      Wir verwenden für den Betrieb unserer Internetseite Cookies, um diese
      nutzerfreundlicher zu gestalten. Einige Elemente unserer Internetseite
      erfordern es, dass der aufrufende Browser auch nach einem Seitenwechsel
      identifiziert werden kann.
    </p>

    <p>
      Cookies sind kleine Dateien, die es ermöglichen, auf dem Zugriffsgerät der
      Nutzer (PC, Smartphone o.ä.) spezifische, auf das Gerät bezogene
      Informationen zu speichern. Sie dienen zum einem der
      Benutzerfreundlichkeit von Webseiten und damit den Nutzern (z.B.
      Speicherung von Logindaten). Zum anderen dienen sie, um die statistische
      Daten der Webseitennutzung zu erfassen und sie zwecks Verbesserung des
      Angebotes analysieren zu können. Die Nutzer können auf den Einsatz der
      Cookies Einfluss nehmen. Die meisten Browser verfügen eine Option mit der
      das Speichern von Cookies eingeschränkt oder komplett verhindert wird.
      Allerdings wird darauf hingewiesen, dass die Nutzung und insbesondere der
      Nutzungskomfort ohne Cookies eingeschränkt werden.
    </p>

    <p>
      Die auf diese Weise erhobenen Daten der Nutzer werden durch technische
      Vorkehrungen pseudonymisiert. Daher ist eine Zuordnung der Daten zum
      aufrufenden Nutzer nicht mehr möglich. Die Daten werden nicht gemeinsam
      mit sonstigen personenbezogenen Daten der Nutzer gespeichert.
    </p>

    <p>
      Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter
      Verwendung von Cookies ist Art. 6 Abs. 1 lit. f DSGVO. Die Rechtsgrundlage
      für die Verarbeitung personenbezogener Daten unter Verwendung technisch
      notweniger Cookies ist Art. 6 Abs. 1 lit. f DSGVO. Die Rechtsgrundlage für
      die Verarbeitung personenbezogener Daten unter Verwendung von Cookies zu
      Analysezwecken ist bei Vorliegen einer diesbezüglichen Einwilligung des
      Nutzers Art. 6 Abs. 1 lit. a DSGVO.
    </p>

    <p>
      Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung
      von Websites für die Nutzer zu vereinfachen. Einige Funktionen unserer
      Internetseite können ohne den Einsatz von Cookies nicht angeboten werden.
      Für diese ist es erforderlich, dass der Browser auch nach einem
      Seitenwechsel wiedererkannt wird.
    </p>

    <p>
      Die Verwendung der Analyse-Cookies erfolgt zu dem Zweck, die Qualität
      unserer Website und ihre Inhalte zu verbessern. Durch die Analyse-Cookies
      erfahren wir, wie die Website genutzt wird und können so unser Angebot
      stetig optimieren.
    </p>

    <p>
      In diesen Zwecken liegt auch unser berechtigtes Interesse in der
      Verarbeitung der personenbezogenen Daten nach Art. 6 Abs. 1 lit. f DSGVO.
    </p>

    <h3>Dienste von Drittanbietern</h3>

    <p>
      Wir haben Inhalte, Dienste und Leistungen anderer Anbieter auf der
      Internetseite integriert. Das sind zum Beispiel Karten, die von
      Google-Maps zur Verfügung gestellt werden, Videos von YouTube sowie
      Grafiken und Bilder anderer Webseiten. Damit diese Daten im Browser des
      Nutzers aufgerufen und dargestellt werden können, ist die Übermittlung der
      IP-Adresse zwingend notwendig. Die Anbieter (im Folgenden als
      „Dritt-Anbieter“ bezeichnet) nehmen also die IP-Adresse des jeweiligen
      Nutzers wahr.
    </p>

    <p>
      Auch wenn wir bemüht sind, ausschließlich Dritt-Anbieter zu nutzen, welche
      die IP-Adresse nur benötigen, um Inhalte ausliefern zu können, haben wir
      keinen Einfluss darauf, ob die IP-Adresse möglicherweise gespeichert wird.
      Dieser Vorgang dient in dem Fall unter anderem statistischen Zwecken.
      Sofern wir Kenntnis davon haben, dass die IP-Adresse gespeichert wird,
      weisen wir unsere Nutzer darauf hin.
    </p>

    <h4>Einsatz und Verwendung von Matomo</h4>

    <p>
      Diese Website benutzt den Open Source Webanalysedienst Matomo. Matomo
      verwendet so genannte "Cookies". Das sind Textdateien, die auf Ihrem
      Computer gespeichert werden und die eine Analyse der Benutzung der Website
      durch Sie ermöglichen. Dazu werden die durch den Cookie erzeugten
      Informationen über die Benutzung dieser Website auf unserem Server
      gespeichert. Die IP-Adresse wird vor der Speicherung anonymisiert.
      Matomo-Cookies verbleiben auf Ihrem Endgerät, bis Sie sie löschen.
    </p>

    <p>
      Die Speicherung von Matomo-Cookies erfolgt auf Grundlage von Art. 6 Abs. 1
      lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der
      anonymisierten Analyse des Nutzerverhaltens, um sowohl sein Webangebot als
      auch seine Werbung zu optimieren. Die durch den Cookie erzeugten
      Informationen über die Benutzung dieser Website werden nicht an Dritte
      weitergegeben. Sie können die Speicherung der Cookies durch eine
      entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen
      Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht
      sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.
      Wenn Sie mit der Speicherung und Nutzung Ihrer Daten nicht einverstanden
      sind, können Sie die Speicherung und Nutzung hier deaktivieren. In diesem
      Fall wird in Ihrem Browser ein Opt-Out-Cookie hinterlegt, der verhindert,
      dass Matomo Nutzungsdaten speichert. Wenn Sie Ihre Cookies löschen, hat
      dies zur Folge, dass auch das Matomo Opt-Out-Cookie gelöscht wird. Das
      Opt-Out muss bei einem erneuten Besuch unserer Seite wieder aktiviert
      werden.
    </p>

    <!-- <p><Opt-OUT PLUGIN INSTALLIEREN></p>Ð -->

    <h4>Zahlungsdienstleister</h4>

    <p style="overflow-wrap: anywhere">
      Wir setzen externe Zahlungsdienstleister ein, über deren Plattformen die
      Nutzer und wir Zahlungstransaktionen vornehmen können. Hierzu gehören
      Paypal (https://www.paypal.com/de/webapps/mpp<br>/ua/privacy-full), Klarna
      (https://www.klarna.com/de/datenschutz/), Stripe
      (https://stripe.com/de/privacy#translation). Im Rahmen der Erfüllung von
      Verträgen setzen wir die Zahlungsdienstleister auf Grundlage des Art. 6
      Abs. 1 lit. b. DSGVO ein. Im Übrigen setzen wir externe
      Zahlungsdienstleister auf Grundlage unserer berechtigten Interessen gem.
      Art. 6 Abs. 1 lit. b. DSGVO ein, um unseren Nutzern effektive und sichere
      Zahlungsmöglichkeit zu bieten. Zu den, durch die Zahlungsdienstleister
      verarbeiteten Daten gehören Bestandsdaten, wie z.B. der Name und die
      Adresse, Bankdaten, wie z.B. Kontonummern oder Kreditkartennummern,
      Passwörter, TANs und Prüfsummen sowie die Vertrags-, Summen und
      empfängerbezogenen Angaben. Die Angaben sind erforderlich, um die
      Transaktionen durchzuführen. Die eingegebenen Daten werden jedoch nur
      durch die Zahlungsdienstleister verarbeitet und bei diesen gespeichert.
      D.h. wir erhalten keine konto- oder kreditkartenbezogenen Informationen,
      sondern lediglich Informationen mit Bestätigung oder Negativbeauskunftung
      der Zahlung. Unter Umständen werden die Daten seitens der
      Zahlungsdienstleister an Wirtschaftsauskunfteien übermittelt. Diese
      Übermittlung bezweckt die Identitäts- und Bonitätsprüfung. Hierzu
      verweisen wir auf die AGB und Datenschutzhinweise der
      Zahlungsdienstleister. Für die Zahlungsgeschäfte gelten die
      Geschäftsbedingungen und die Datenschutzhinweise der jeweiligen
      Zahlungsdienstleister, welche innerhalb der jeweiligen Webseiten, bzw.
      Transaktionsapplikationen abrufbar sind. Wir verweisen auf diese ebenfalls
      zwecks weiterer Informationen und Geltendmachung von Widerrufs-,
      Auskunfts- und anderen Betroffenenrechten.
    </p>

    <h4>Einsatz von PayPal als Zahlungsart</h4>

    <p>
      Sollten Sie sich im Rahmen Ihres Bestellvorgangs für eine Bezahlung mit
      dem Online-Zahlungsdienstleister PayPal entscheiden, werden im Rahmen der
      so ausgelösten Bestellung Ihre Kontaktdaten an PayPal übermittelt. PayPal
      ist ein Angebot der PayPal (Europe) S.à.r.l. & Cie. S.C.A., 22-24
      Boulevard Royal, L-2449 Luxembourg. PayPal übernimmt dabei die Funktion
      eines Online-Zahlungsdienstleisters sowie eines Treuhänders und bietet
      Käuferschutzdienste an.
    </p>

    <p>
      Bei den an PayPal übermittelten personenbezogenen Daten handelt es sich
      zumeist um Vorname, Nachname, Adresse, Telefonnummer, IP-Adresse,
      E-Mail-Adresse, oder andere Daten, die zur Bestellabwicklung erforderlich
      sind, als auch Daten, die im Zusammenhang mit der Bestellung stehen, wie
      Anzahl der Artikel, Artikelnummer, Rechnungsbetrag und Steuern in Prozent,
      Rechnungsinformationen, usw.
    </p>

    <p>
      Diese Übermittelung ist zur Abwicklung Ihrer Bestellung mit der von Ihnen
      ausgewählten Zahlungsart notwendig, insbesondere zur Bestätigung Ihrer
      Identität, zur Administration Ihrer Zahlung und der Kundenbeziehung.
    </p>

    <p>
      Bitte beachten Sie jedoch: Personenbezogenen Daten können seitens PayPal
      auch an Leistungserbringer, an Subunternehmer oder andere verbundene
      Unternehmen weitergegeben werden, soweit dies zur Erfüllung der
      vertraglichen Verpflichtungen aus Ihrer Bestellung erforderlich ist oder
      die personenbezogenen Daten im Auftrag verarbeitet werden sollen.
    </p>

    <p>
      Abhängig von der über PayPal ausgewählten Zahlungsart, z.B. Rechnung oder
      Lastschrift, werden die an PayPal übermittelten personenbezogenen Daten
      von PayPal an Wirtschaftsauskunfteien übermittelt. Diese Übermittlung
      dient der Identitäts- und Bonitätsprüfung in Bezug auf die von Ihnen
      getätigte Bestellung. Um welche Auskunfteien es sich hierbei handelt und
      welche Daten von PayPal allgemein erhoben, verarbeitet, gespeichert und
      weitergegeben werden, entnehmen Sie der Datenschutzerklärung von PayPal
      unter https://www.paypal.com/de/webapps/mpp<br>/ua/privacy-full
    </p>

    <h4>Einsatz von Klarna als Zahlungsart</h4>

    <p>
      Sollten Sie sich im Rahmen des Bestellvorgangs für Klarna Rechnung und
      Klarna Ratenkauf der Klarna AB, geschäftsansässig Sveavägen 46, 111 34
      Stockholm, Schweden, als Zahlungsoption bei uns entscheiden, willigen Sie
      ein, dass wir die für die Abwicklung des Rechnungskaufes und einer
      Identitäts- und Bonitätsprüfung notwendigen personenbezogenen Daten
      erheben und an Klarna AB übermitteln. Dies sind insbesondere Vor- und
      Nachname, Titel, Adresse, Geburtsdatum, Geschlecht, Email-Adresse,
      IP-Adresse, Telefonnummer, Mobiltelefonnummer, sowie Daten, die für die
      Abwicklung des Rechnungskaufs notwendig sind und Daten die im Zusammenhang
      mit der Bestellung stehen, wie Anzahl der Artikel, Artikelnummer,
      Rechnungsbetrag und Steuern in Prozent, Rechnungsinformationen,
      Bankverbindung, Kartennummer, Gültigkeitsdatum, CCV Code, Informationen
      über Waren/ Dienstleistungen, Historische Informationen, Angaben über ihre
      bisherigen Einkäufe, Zahlungshistorie, eventuelle Ablehnungen, finanzielle
      Informationen, Angaben über eventuelle Kreditverpflichtungen und
      Zahlungsanmerkungen, Informationen über die Interaktion zwischen Ihnen und
      dem Klarna Checkout, Seitenladezeiten, Downloadfehler und Methoden, die
      verwendet wurden, um die angezeigte Seite zu verlassen, Informationen zur
      elektronischen Kommunikation, Empfangsbestätigungen, Geräteinformationen,
      Geographische Informationen.
    </p>

    <p>
      Diese Übermittelung ist zur Abwicklung Ihres Einkaufs mit der von Ihnen
      gewünschten Rechnungsabwicklung notwendig, insbesondere zur Bestätigung
      Ihrer Identität, zur Administration Ihrer Zahlung und der Kundenbeziehung,
      zur Kundenanalyse, zur Administration von Klarnas Dienstleistungen und für
      interne Prozesse, einschließlich Behebung von Störungen, Datenanalysen,
      internen Tests, Entwicklung, statistische Zwecke, zur Verbesserung der
      Dienstleistungen von Klarna, um sicherzustellen, dass die notwendige
      Informationen für Sie und Ihr Gerät so effektiv wie möglich angezeigt
      werden, zur Vermeidung von Missbrauch oder unsachgemäßer Anwendung der
      Dienstleistungen Klarnas, im Rahmen des Bestrebens Klarnas, die
      Dienstleistungen so sicher wie möglich zu gestalten, zur Beurteilung,
      welche Zahlungsmethoden wir Ihnen über Klarna zur Verfügung stellen
      können, zur Durchführung interner Kreditbeurteilungen, zur Durchführung
      von Risikoanalysen und Risikomanagement, zur Geschäftsentwicklung und zur
      Befolgung anwendbaren Rechts. Klarna hat dabei ein berechtigtes Interesse
      an der Übermittlung der personenbezogenen Daten des Bestellers und
      benötigt diese, um bei Wirtschaftsauskunfteien zum Zwecke der Identitäts-
      und Bonitätsprüfung Auskunft einzuholen. In Deutschland kann die
      Weitergabe der vorgenannten Daten an folgenden Wirtschaftsauskunfteien
      erfolgen:
    </p>

    <ul>
      <li>
        Arvato Infoscore Consumer Data GmbH und Infoscore Consumer Data GmbH,
        Rheinstraße 99, 76532 Baden-Baden
      </li>
      <li>
        Bürgel Wirtschaftsinformationen GmbH & Co. KG, Postfach 5001 66, 22701
        Hamburg
      </li>
      <li>Creditreform Boniversum GmbH, Hellersbergstraße 11, 41460 Neuss</li>

      <li>Deltavista GmbH, Freisinger Landstr. 74 80939 München</li>

      <li>SCHUFA Holding AG, Kormoranweg 5, 65201 Wiesbaden</li>
    </ul>

    <p>
      Zudem kann Klarna Ihre personenbezogenen Daten anderen Unternehmen
      innerhalb der Klarna Gruppe, Leistungserbringern und Subunternehmern zur
      Verfügung stellen, soweit dies zur Erfüllung der vertraglichen Beziehungen
      mit Ihnen oder mit diesen erforderlich ist.
    </p>

    <p>
      Im Rahmen der Entscheidung über die Begründung, Durchführung oder
      Beendigung des vertraglichen Verhältnisses erhebt und nutzt Klarna
      Informationen zu dem bisherigen Zahlungsverhalten des Käufers sowie
      Wahrscheinlichkeitswerte zu diesem Verhalten in der Zukunft. Die
      Berechnung dieses Scorings seitens Klarna wird auf Basis wissenschaftlich
      anerkannter mathematisch-statistischer Verfahren durchgeführt.
    </p>

    <p>
      Es besteht die Möglichkeit Ihre Einwilligung zur Verwendung der
      personenbezogenen Daten jederzeit gegenüber Klarna zu widerrufen. Jedoch
      bleibt Klarna auch in diesem Fall u.U. weiterhin dazu berechtigt, die
      personenbezogenen Daten zu verarbeiten, zu nutzen und zu übermitteln,
      insofern dies zur vertragsmäßigen Zahlungsabwicklung durch Klarnas Dienste
      notwendig ist, rechtlich vorgeschrieben ist, oder von einem Gericht oder
      einer Behörde gefordert wird.
    </p>

    <p>
      Weitergehende Informationen zum Datenschutz erhalten Sie auch von Klarna
      direkt:
    </p>

    <p>
      Datenschutzerklärung der Klarna AB <br />
      Sie können jederzeit Auskunft über die von Klarna gespeicherten
      personenbezogenen Daten erhalten, indem Sie sich an Datenschutz@klarna.de
      wenden.
    </p>

    <h4>Einsatz von Stripe zur Zahlungsabwicklung</h4>

    <p>
      Sollten Sie sich im Rahmen des Bestellvorgangs für eine Zahlungsart des
      Paymentdienstleisters Stripe entscheiden, erfolgt die Zahlungsabwicklung
      über den Paymentdienstleister Stripe Payments Europe Ltd., 1 Grand Canal
      Street Lower, Grand Canal Dock, Dublin, Irland, an den wir Ihre im Rahmen
      des Bestellvorgangs mitgeteilten Informationen nebst den Informationen
      über Ihre Bestellung (Name, Anschrift, Kontonummer, Bankleitzahl, evtl.
      Kreditkartennummer, Rechnungsbetrag, Währung und Transaktionsnummer) gemäß
      Art. 6 Abs. 1 lit. b DSGVO weitergeben. Nähere Informationen zum
      Datenschutz von Stripe finden Sie unter der URL
      https://stripe.com/de/privacy#translation.
    </p>

    <p>
      Stripe behält sich vor, eine Bonitätsprüfung auf der Grundlage
      mathematisch-statistischer Verfahren durchzuführen, um das berechtigte
      Interesse an der Feststellung der Zahlungsfähigkeit des Nutzers zu wahren.
      Die für eine Bonitätsprüfung notwendigen und im Rahmen der
      Zahlungsabwicklung erhaltenen personenbezogenen Daten übermittelt Stripe
      gegebenenfalls an ausgewählte Auskunfteien, welche Stripe Nutzern auf
      Anfrage offenlegt. Die Bonitätsauskunft kann Wahrscheinlichkeitswerte
      enthalten (sog. Score-Werte). Soweit Score-Werte in das Ergebnis der
      Bonitätsauskunft einfließen, haben diese ihre Grundlage in einem
      wissenschaftlich anerkannten mathematisch-statistischem Verfahren. In die
      Berechnung der Score-Werte fließen unter anderem, aber nicht
      ausschließlich, Anschriftendaten ein. Das Ergebnis der Bonitätsprüfung in
      Bezug auf die statistische Zahlungsausfallwahrscheinlichkeit verwendet
      Stripe zum Zwecke der Entscheidung über die Nutzungsberechtigung für die
      gewählte Zahlungsmethode.
    </p>

    <p>
      Sie können dieser Verarbeitung Ihrer Daten jederzeit durch eine Nachricht
      an Stripe oder die beauftragten Auskunfteien widersprechen. <br />
      Jedoch bleibt Stripe ggf. weiterhin berechtigt, Ihre personenbezogenen
      Daten zu verarbeiten, sofern dies zur vertragsgemäßen Zahlungsabwicklung
      erforderlich ist.
    </p>

    <h3>
      Zusammenarbeit mit Auftragsverarbeitern, gemeinsam Verantwortlichen und
      Dritten
    </h3>

    <p>
      Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen Personen
      und Unternehmen (Auftragsverarbeitern, gemeinsam Verantwortlichen oder
      Dritten) offenbaren, sie an diese übermitteln oder ihnen sonst Zugriff auf
      die Daten gewähren, erfolgt dies nur auf Grundlage einer gesetzlichen
      Erlaubnis (z.B. wenn eine Übermittlung der Daten an Dritte, wie an
      Zahlungsdienstleister, zur Vertragserfüllung erforderlich ist), Nutzer
      eingewilligt haben, eine rechtliche Verpflichtung dies vorsieht oder auf
      Grundlage unserer berechtigten Interessen (z.B. beim Einsatz von
      Beauftragten, Webhostern, etc.).
    </p>

    <p>
      Sofern wir Daten anderen Unternehmen unserer Unternehmensgruppe
      offenbaren, übermitteln oder ihnen sonst den Zugriff gewähren, erfolgt
      dies insbesondere zu administrativen Zwecken als berechtigtes Interesse
      und darüber hinausgehend auf einer den gesetzlichen Vorgaben
      entsprechenden Grundlage.
    </p>

    <h4>Übermittlungen in Drittländer</h4>

    <p>
      Sofern wir Daten in einem Drittland (d.h. außerhalb der Europäischen Union
      (EU), des Europäischen Wirtschaftsraums (EWR) oder der Schweizer
      Eidgenossenschaft) verarbeiten oder dies im Rahmen der Inanspruchnahme von
      Diensten Dritter oder Offenlegung, bzw. Übermittlung von Daten an andere
      Personen oder Unternehmen geschieht, erfolgt dies nur, wenn es zur
      Erfüllung unserer (vor)vertraglichen Pflichten, auf Grundlage Ihrer
      Einwilligung, aufgrund einer rechtlichen Verpflichtung oder auf Grundlage
      unserer berechtigten Interessen geschieht. Vorbehaltlich gesetzlicher oder
      vertraglicher Erlaubnisse, verarbeiten oder lassen wir die Daten in einem
      Drittland nur beim Vorliegen der gesetzlichen Voraussetzungen. D.h. die
      Verarbeitung erfolgt z.B. auf Grundlage besonderer Garantien, wie der
      offiziell anerkannten Feststellung eines der EU entsprechenden
      Datenschutzniveaus (z.B. für die USA durch das „Privacy Shield“) oder
      Beachtung offiziell anerkannter spezieller vertraglicher Verpflichtungen.
    </p>

    <h4>Datenschutz bei Bewerbungen und im Bewerbungsverfahren</h4>

    <p>
      Wir erheben und verarbeiten die personenbezogenen Daten von Bewerbern zum
      Zwecke der Abwicklung des Bewerbungsverfahrens. Die Verarbeitung kann auch
      auf elektronischem Wege erfolgen. Dies ist insbesondere dann der Fall,
      wenn ein Bewerber entsprechende Bewerbungsunterlagen auf dem
      elektronischen Wege, beispielsweise per E-Mail oder über ein auf der
      Internetseite befindliches Webformular, an uns übermittelt. Schließen wir
      einen Anstellungsvertrag mit einem Bewerber, werden die übermittelten
      Daten zum Zwecke der Abwicklung des Beschäftigungsverhältnisses unter
      Beachtung der gesetzlichen Vorschriften gespeichert. Wird von uns kein
      Anstellungsvertrag mit dem Bewerber geschlossen, so werden die
      Bewerbungsunterlagen zwei Monate nach Bekanntgabe der Absageentscheidung
      automatisch gelöscht, sofern einer Löschung keine sonstigen berechtigten
      Interessen unsererseits entgegenstehen. Sonstiges berechtigtes Interesse
      in diesem Sinne ist beispielsweise eine Beweispflicht in einem Verfahren
      nach dem Allgemeinen Gleichbehandlungsgesetz (AGG).
    </p>

    <h3>Rechte der betroffenen Person</h3>

    <p>
      Sie haben das Recht: <br />
      (1) gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten
      personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft
      über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten,
      die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt
      wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts
      auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder
      Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer
      Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen
      einer automatisierten Entscheidungsfindung einschließlich Profiling und
      ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen;
      <br />
      (2) gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder
      Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu
      verlangen; <br />
      (3) gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten
      personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur
      Ausübung des Rechts auf freie Meinungsäußerung und Information, zur
      Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen
      Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von
      Rechtsansprüchen erforderlich ist; (4) gemäß Art. 18 DSGVO die
      Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen,
      soweit die Richtigkeit der Daten von Ihnen bestritten wird, die
      Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir
      die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung,
      Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß
      Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben; (5)
      gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns
      bereitgestellt haben, in einem strukturierten, gängigen und
      maschinenlesebaren Format zu erhalten oder die Übermittlung an einen
      anderen Verantwortlichen zu verlangen; <br />
      (6) gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit
      gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir die
      Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft
      nicht mehr fortführen dürfen und <br />
      (7) gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In
      der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen
      Aufenthaltsortes oder Arbeitsplatzes oder unseres Kanzleisitzes wenden.
      <br />
    </p>

    <h3>Widerspruchsrecht</h3>

    <p>
      Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation
      ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden
      personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f
      DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese
      Bestimmungen gestütztes Profiling.
    </p>

    <p>
      Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen
      Daten nicht mehr, es sei denn, er kann zwingende schutzwürdige Gründe für
      die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten
      überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder
      Verteidigung von Rechtsansprüchen.
    </p>

    <p>
      Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um
      Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch
      gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum
      Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling,
      soweit es mit solcher Direktwerbung in Verbindung steht.
    </p>

    <p>
      Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden
      die Sie betreffenden personenbezogenen Daten nicht mehr für diese Zwecke
      verarbeitet.
    </p>

    <p>
      Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten
      der Informationsgesellschaft – ungeachtet der Richtlinie 2002/58/EG – Ihr
      Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen
      technische Spezifikationen verwendet werden.
    </p>

    <h3>Automatisierte Entscheidung im Einzelfall einschließlich Profiling</h3>

    <p>
      Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten
      Verarbeitung – einschließlich Profiling – beruhenden Entscheidung
      unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet
      oder Sie in ähnlicher Weise erheblich beeinträchtigt. Dies gilt nicht,
      wenn die Entscheidung
    </p>

    <p>
      (1) für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und
      dem Verantwortlichen erforderlich ist, <br />
      (2) aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten,
      denen der Verantwortliche unterliegt, zulässig ist und diese
      Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und
      Freiheiten sowie Ihrer berechtigten Interessen enthalten oder <br />
      (3) mit Ihrer ausdrücklichen Einwilligung erfolgt. <br />
      Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien
      personenbezogener Daten nach Art. 9 Abs. 1 DSGVO beruhen, sofern nicht
      Art. 9 Abs. 2 lit. a oder g gilt und angemessene Maßnahmen zum Schutz der
      Rechte und Freiheiten sowie Ihrer berechtigten Interessen getroffen
      wurden.
    </p>

    <p>
      Hinsichtlich der in (1) und (3) genannten Fälle trifft der Verantwortliche
      angemessene Maßnahmen, um die Rechte und Freiheiten sowie Ihre
      berechtigten Interessen zu wahren, wozu mindestens das Recht auf Erwirkung
      des Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung
      des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört.
    </p>

    <p>
      Als verantwortungsbewusstes Unternehmen verzichten wir auf eine
      automatische Entscheidungsfindung oder ein Profiling.
    </p>

    <h3>Datensicherheit</h3>

    <p>
      Wir verwenden innerhalb des Website-Besuchs das verbreitete SSL-Verfahren
      (Secure Socket Layer) in Verbindung mit der jeweils höchsten
      Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird. In der
      Regel handelt es sich dabei um eine 256 Bit Verschlüsselung. Falls Ihr
      Browser keine 256-Bit Verschlüsselung unterstützt, greifen wir stattdessen
      auf 128-Bit v3 Technologie zurück. Ob eine einzelne Seite unseres
      Internetauftrittes verschlüsselt übertragen wird, erkennen Sie an der
      geschlossenen Darstellung des Schüssel- beziehungsweise Schloss-Symbols in
      der unteren Statusleiste Ihres Browsers.
    </p>

    <p>
      Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer
      Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder vorsätzliche
      Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder
      gegen den unbefugten Zugriff Dritter zu schützen. Unsere
      Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung
      fortlaufend verbessert.
    </p>

    <h3>Aktualität und Änderung dieser Datenschutzerklärung</h3>

    <p>
      Diese Datenschutzerklärung ist aktuell gültig und hat den Stand 27. April
      2021.
    </p>

    <p>
      Durch die Weiterentwicklung unserer Website und Angebote darüber oder
      aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben
      kann es notwendig werden, diese Datenschutzerklärung zu ändern. Die
      jeweils aktuelle Datenschutzerklärung kann jederzeit über diese Adresse
      von Ihnen abgerufen und ausgedruckt werden.
    </p>


    <br>
    <br>
    <br>
    <br>
    <br>
    <p>TERMS OF USE</p>

    <p>This Agreement governs the contractual relationship between WHOLE GmbH, Schönhauser Allee 167a in 10435 Berlin, represented by the Managing Directors Raquel Fedato Pogoda, Giacomo Garavelloni, Cristian Pereira Phillips, Danilo Rosato (hereinafter "Provider") and you (hereinafter "User"), including Provider's Privacy Policy for the use of the App "Whole Festival" (hereinafter "Application" or "Contract Software"), unless otherwise agreed between User and Provider. </p>

    <h4>1. SUBJECT MATTER OF THE CONTRACT</h4>
    <p>Subject matter of this contract is the use of the application "Whole Festival" via the Internet as well as through other access options and further platforms (iPhone Apps, Android Apps, etc.) in the object code and the granting of rights of use named below. The application is offered by Provider free of charge on the Internet.</p>

    <h4>2. CONCLUSION OF THE CONTRACT</h4>
    <p>2.1 By accepting this contract and/or using the Application, you agree to the provisions of this contract. The contract is concluded exclusively between Provider and User. Manufacturers of operating systems on mobile devices or other operators of portals or manufacturers of mobile devices are not parties to this contract.</p>
    <p>2.2 Insofar as further chargeable services are offered within the scope of the Application, Provider´s General Terms and Conditions shall apply to this extent. The use of the Application is free of charge for the User.</p>

    <h4>3. GRANTING OF RIGHTS OF USE</h4>
    <p>3.1 Provider grants the User the non-exclusive right to use the Application for an unlimited period of time. The Application may only be used by User on the mobile device owned by the User. The permitted use includes downloading the Application to the internal memory of the mobile device as well as its intended use by the User. Under no circumstances User shall have the right to rent or otherwise license the Application to third parties, to publicly reproduce or make it accessible by wire or wireless means, or to make it available to third parties against payment or free of charge, e. g. by way of Application Service Providing or like "Software as a Service". </p>
    <p>3.2 User shall be entitled to create a backup copy if this is necessary to secure future use. The User shall visibly affix the note "Backup Copy" as well as a copyright notice of the Provider to the created backup copy.</p>
    <p>3.3 If User uses the Contract Software to an extent that exceeds the acquired rights of use qualitatively (with regard to the type of permitted use) or quantitatively (with regard to the number of acquired licenses), then User shall immediately acquire the rights of use necessary for the permitted use. If he fails to do so, Provider shall assert the rights to which he is entitled. </p>
    <p>3.4 Copyright mention, serial numbers and other features serving to identify the program may not be removed from the application or changed.</p>

    <h4>4. SECURITY MEASURES AND RIGHTS OF THIRD PARTIES</h4>
    <p>4.1 User shall take appropriate measures to protect the application and the data for online access against use by unauthorized third parties. In particular, all copies of the contractual software as well as the access data shall be kept in a protected place. </p>
    <p>4.2 User guarantees that he is authorized to use the data he uses with the application and that these data have not been obtained in whole or in part in an unauthorized way.</p>
    <p>4.3 User shall ensure that the data, graphics, information, etc. supplied do not include any content that is anti-constitutional, immoral, glorifies violence, violates criminal law standards, is discriminatory or suitable for depreciating others on the basis of their ideological, political or religious attitudes. Furthermore, no contents may be provided which are likely to infringe the rights of third parties, in particular industrial property rights and copyrights.</p>
    <p>4.4 User shall indemnify Provider as well as Provider's legal successors against all claims of third parties that may be asserted against Provider or its legal successors. User shall notify Provider without delay of any infringements of the contractual rights that become known to him. Provider is entitled to take appropriate measures to defend himself against claims of third parties or to pursue their rights. User must coordinate his own measures with Provider in advance. The indemnification also includes an advance payment or reimbursement of the costs incurred by Provider as a result of the necessary legal prosecution/defense, insofar as these are not to be reimbursed by third parties. </p>

    <h4>5. DATA PROTECTION</h4>
    <p>By using the respective function and by entering his data, User agrees that Provider stores, processes and uses User´s required personal data as well as the personal data entered by him via third parties, in compliance with the legal provisions of the Federal Data Protection Act (BDSG) and the General Data Protection Regulation (DSGVO) in order to provide the functions of the Application. Details are regulated by Provider´s data protection regulations. </p>

    <h4>6. WARRANTY AND LIABILITY</h4>
    <p>6.1 Loading and use of the Application is on User´s risk. User has no right to claim that certain state and/or a certain function and/or additional services are maintained or brought about. In particular, User accepts that an application - like any software - cannot be error-free.</p>
    <p>6.2 To the extent permitted by law, Provider does not warrant the undisturbed and/or error-free use of the Application or Data or that defects will be corrected. Particularly is excluded a warranty by Provider for the use or the results of the use of the application or data with regards to correctness, completeness, accuracy and reliability.</p>
    <p>6.3 User expressly agrees that Provider shall not be liable, to the extent permitted by law, for any direct, indirect, incidental, special, consequential, exemplary or any other damages, including loss of profits, goodwill, use, data or other intangible losses arising out of the use or inability to use  of the Application or Data or to procure substitute goods and/or services arising out of the use of the Application or Data or any defect contained therein.</p>
    <p>6.4 Any liability of Provider for injury to life, limb or health as well as for the statutory provisions of the Product Liability Act and for guarantees assumed by Provider shall remain unaffected.</p>

    <h4>7. DURATION AND TERMINATION OF CONTRACT  </h4>
    <p>7.1 The contract between User and Provider is concluded for an indefinite period and may be terminated by User at any time. The termination requires a declaration of termination in writing (e. g. e-mail) to the address given in the imprint. On effectiveness of termination Provider will block User's account.</p>
    <p>7.2 The right to extraordinary termination without notice remains unaffected. A right to extraordinary termination exists for Provider in particular if:</p>
    <p>7.2.1. User has supplied to Provider incomplete and untrue information,</p>
    <p>7.2.2. User misuses Provider´s offer and does not use it in accordance with these provisions.</p>
    <p>7.3 Upon User´s request, Provider shall supply to User the data submitted by User in a standard machine-readable file format (JSON file). A claim of User for further data, especially printing data, is excluded.</p>

    <h4>8. USE IN BREACH OF CONTRACT</h4>
    <p>Provider is entitled to block access to the contractual software and its data in the event of an unlawful violation by User of one of the essential obligations set forth in this Agreement. Access will be restored only after the violation of the affected essential duty has been permanently eliminated or the risk of repetition has been ensured by issuing to Provider an appropriate declaration to cease and desist with penalty clause. </p>

    <h4>9. UPDATING OF SOFTWARE</h4>
    <p>Provider reserves the right to extend, reduce or change the services and/or functionalities contained in the contractual software or to make other further developments to the contractual software. In the event of a reduction in services and/or functionalities of the contractual software, User shall have an extraordinary right to terminate this contract, if the balance of services rendered and remuneration received is more than insignificantly affected and the parties cannot agree on any other provision for the continuation of this contract.</p>

    <h4>10. FORCE MAJEURE</h4>
    <p>10.1 Provider shall be released from the obligation to perform under this Agreement if and to the extent that the non-performance of services is due to the occurrence of circumstances of force majeure after the conclusion of the Agreement.</p>
    <p>10.2. Circumstances of force majeure shall include, for example, war, strikes, riots, expropriations, cardinal changes in law, storms, floods and other natural disasters as well as other circumstances for which the Provider is not responsible, in particular water ingress, power failures and interruptions or destruction of data-carrying lines.</p>
    <p>10.3 Each contract party shall notify the other contract party of the occurrence of a case of force majeure immediately and in writing.</p>

    <h4>11. FINAL PROVISIONS</h4>
    <p>11.1 Additions or amendments to this contract must be made in writing. This shall also apply to this clause relating to the written form.</p>
    <p>11.2 To the extent permitted by law, the place of performance and jurisdiction for services and payments as well as for all disputes arising between the parties shall be Provider´s registered office. In case of end users, the place of performance and jurisdiction shall be the User´s registered office. If the private end user does not have a residence within the European Union, the place of jurisdiction shall be Provider´s registered office. In dealings with end users within the European Union, the law at end user's place of residence may also be applicable, if that is mandatory under consumer law.</p>
    <p>11.3 If one or more clauses of this contract are invalid or become invalid in course of time, the validity of the remaining contract shall not be affected. The invalid clauses shall be replaced by the statutory provisions. The statutory provisions shall also apply in the event of an omission.</p>
    <p>11.4 The contracting parties shall inform each other without delay of all circumstances which could be of importance for the execution of this contract.</p>
    <p>11.5 Any Internet connection costs (telecommunication costs) incurred shall be borne by User. </p>
    <p>11.6 Provider reserves the right to change these Terms of Use if this appears necessary, e. g. due to a change in the legal situation, and the contract party will not be disadvantaged by this contrary to good faith. Provider shall notify User in writing or text form in case of any amendment to these General Terms of Use. In this case, User may object to the amendment of the Terms of Use within a reasonable period of time to be informed at that time. If the User does not object or does not object in time to the amendment of the Terms of Use, the amendments shall become part of the contract concluded with User after expiry of the reasonable period of time.</p>

    <p>***<br>
    Stand: August 2021</p>

    <br>
    <br>
    <br>
    <br>
    <br>

    <h3>Name and address of the company</h3>

    <p>
      The responsible party within the meaning of the German “Data Protection
      Basic Regulation” (hereinafter &quot;DSGVO&quot;) and other national data
      protection laws of the EU member states as well as other data protection
      provisions is:
    </p>

    <p>
      WHOLE GmbH, Schönhauser Allee 167a in 10435 Berlin, represented by the
      managing directors Christian Phillips, Danilo Rosato, Giacomo
      Garavellonie, Giovanni Turco, Jacob Meehan and Raquel Fedato.
      <br />
      Phone: +49 30 83217850 <br />
      E-mail: contact@wholefestival.com
    </p>

    <h3>General Data Processing</h3>

    <h4>Scope and Permission of Personal Data Processing</h4>

    <p>
      As a matter of principle, we only collect and use personal data of our
      users to the extent that this is necessary to provide the functionality of
      our website as well as our contents and services. The collection and use
      of personal data of our users regularly is carried out after consent of
      the user only. <br />
      only <br />
      An exception applies in those cases where it is not possible to obtain
      prior consent for actual reasons and processing of the data is permitted
      by legal regulations. <br />
    </p>

    <p>
      The legal basis for processing operations of personal data with prior
      obtaining consent of the data subject is Art. 6 para. 1 lit. a of the
      DSGVO. The legal basis for processing of personal data to fulfill a legal
      obligation is Art. 6 para. 1 lit. b of the DSGVO. Art. 6 (1) lit. c of the
      DSGVO is the legal basis for processing of personal data required for the
      performance of a contract or for the implementation of pre-contractual
      measures. In the event that vital interests of the data subject or another
      natural person make processing of personal data necessary, Art. 6 (1) lit.
      d DSGVO serves as the legal basis. If the processing is necessary to
      protect a legitimate interest of our company or a third party and if this
      interest outweighs the interest of the data subject, taking into account
      the fundamental rights and freedoms, Art. 6 (1) lit. f DSGVO is the legal
      basis for processing of the data.
    </p>

    <h4>Data Deletion and Storage Period</h4>

    <p>
      The personal data of the data subject will be deleted or blocked as soon
      as the purpose of storage lapses. A longer data storage is allowed if
      required by a statutory provision governing the data processing. In this
      case, the data will be blocked or deleted when the legally prescribed
      storage period expires, unless there is a need for further storage of the
      data for the conclusion or performance of a contract.
    </p>

    <h3>Data Processing Caused by Use of Our Website</h3>

    <h4>Visiting Our Website</h4>

    <p>
      When visiting our website, the browser used on your terminal device
      automatically sends information to the server of our website. This
      information is temporarily stored in a so-called logfile. The following
      information is collected without your assistance and stored until its
      automated deletion: IP address of the requesting computer, date and time
      of access, name and URL of the accessed file, website from which the
      access is made (referrer URL), browser used and, if applicable, the
      operating system of your computer and the name of your access provider.
    </p>

    <p>
      We process the aforementioned information for the purpose of ensuring a
      smooth connection setup of the website, a comfortable use of our website,
      ensuring evaluating system security and stability, as well as for other
      administrative purposes.
    </p>

    <p>
      The legal basis for our data processing is Art. 6 para. 1 p. 1 lit. f of
      the DSGVO. Our legitimate interest is a consequence of the purposes for
      data collection listed above. In no case do we use the collected data for
      the purpose of drawing conclusions about your person.
    </p>

    <h4>Use of Online Store Functions</h4>

    <p>
      We process the data of our customers as part of the ordering processes in
      our online store in order to enable them to select and order the selected
      products and services, and to make their payment and delivery, or better
      to execute their orders.
    </p>

    <p>
      The processed data include inventory data, communication data, contract
      data, payment data and the data subjects of the processing include our
      customers, prospective customers and other business partners. The
      processing is carried out for the purpose of providing contractual
      services in the context of operating an online store, billing, delivery
      and customer services. Here we use session cookies for storing the
      shopping cart content and permanent cookies for storing the login status.
    </p>

    <p>
      The processing is carried out on the basis of Art. 6 para. 1 lit. b
      (execution of order transactions) and c (legally required archiving) of
      the DSGVO. Here the information marked as required is necessary for the
      justification and fulfillment of the contract. We disclose the data to
      third parties only in the context of delivery, payment or in the scope of
      legal permissions and obligations to legal advisors and authorities. The
      data are only processed in third countries if this is necessary for the
      fulfillment of the contract (e.g., at request of the customer for delivery
      or payment).
    </p>

    <p>
      Users can optionally create a user account, in which they can view their
      orders in particular. As part of the registration process, the required
      mandatory information will be provided to users. User accounts are not
      public and cannot be indexed by search engines. If users have terminated
      their user account, their data with regard to the user account will be
      deleted, subject to their retention necessary for commercial or tax
      reasons in accordance with Art. 6 para. 1 lit. c of the DSGVO. Information
      in the customer account remains until its deletion with subsequent
      archiving in the event of a legal obligation. It is the responsibility of
      the users to save their data in the event of termination before the end of
      the contract.
    </p>

    <p>
      Within the scope of registration and renewed logins as well as the use of
      our online services, we store the IP address and the time of the
      respective user action. The storage is based on our legitimate interests,
      as well as those of users in protection against abuse and other
      unauthorized use. As a principle, these data are not passed on to third
      parties, unless it is necessary for the pursuit of our claims or if there
      is a legal obligation to do so pursuant to Art. 6 para. 1 lit. c of the
      DSGVO.
    </p>

    <p>
      The deletion takes place after the expiry of legal warranty and comparable
      obligations, the necessity of keeping the data is reviewed every three
      years; in the case of legal archiving obligations, the deletion takes
      place after their expiry (end of commercial law (6 years) and tax law (10
      years) retention obligation).
    </p>

    <h4>Use of Our Contact Form</h4>

    <p>
      Our website contains a contact form that can be used to contact us
      electronically. If a user takes advantage of this option, the data entered
      in the contact form as well as the IP address, date and time are
      transmitted to us and stored. For the processing of the data, your consent
      is obtained during the submission process and reference is made to this
      privacy policy. The legal basis for the processing of the data in this
      case is Art. 6 para. 1 lit. a DSGVO.
    </p>

    <p>
      Alternatively, it is possible to contact us via the e-mail address
      provided. In this case, user's personal data transmitted with the e-mail
      will be stored. In this regard, no data will be passed on to third
      parties. The legal basis for the processing of these data is Art. 6 para.
      1 lit. f of the DSGVO. If the e-mail contact aims at the conclusion of a
      contract, the additional legal basis for data processing is Art. 6 (1)
      lit. b of the DSGVO.
    </p>

    <p>
      Data processing for the purpose of contacting us is based on your
      voluntarily given consent according to Art. 6 para. 1 p. 1 lit. a DSGVO.
      The personal data collected by us for the use of the contact form will be
      automatically deleted after completion of your request.
    </p>

    <h4>Registration</h4>

    <p>
      You have the option of registering on our website by providing personal
      data. The respective input mask used for registration determines which
      personal data will be transmitted to us in the process. The personal data
      you enter will be processed exclusively for internal use for our own
      purposes. We may arrange the data to be passed on to one or more order
      processors, who will also use your personal data exclusively for an
      internal use attributable to us
    </p>

    <p>
      Furthermore, by registering on our website, the IP address of the data
      subject assigned by the Internet service provider (ISP), as well as the
      date and time of registration are stored. These data are stored because it
      is the only way to prevent misuse of our services and, if necessary, to
      enable us to investigate criminal offences that have been committed. In
      this respect, the storage of data is necessary for security purposes. As a
      matter of principle, these data are not passed on to third parties unless
      there is a legal obligation to pass them on or the passing on serves the
      purpose of criminal prosecution.
    </p>

    <p>
      Your registration, based on voluntary indication of personal data, serves
      to offer you contents or services, which can be offered to registered
      users only due to the nature of the matter. Registered persons are free to
      change the personal data provided during registration at any time or to
      have it completely deleted from our database.
    </p>

    <p>
      Upon request, we will provide for any data subject at any time information
      on what personal data are stored regarding the data subject. Furthermore,
      we will correct or delete personal data at the request or notice of the
      data subject, provided that this does not conflict with any statutory
      retention obligations.
    </p>

    <h4>Newsletter</h4>

    <p>
      On our website we offer you the possibility to subscribe to our
      newsletter. With this newsletter we inform you about our offers at regular
      intervals. To receive our newsletter, you need a valid e-mail address. We
      will check the e-mail address you have entered to ensure that you are
      indeed the owner of the e-mail address provided or that the owner has
      authorized to receive the newsletter.
    </p>

    <p>
      With your registration to receive our newsletter, we will store your IP
      address and the date and time of your registration. This serves as a
      safeguard on our part in the event that a third party misuses your e-mail
      address and subscribes to our newsletter without your knowledge. No
      further data are collected on our part. The data collected in this way are
      used exclusively for the purpose of receiving our newsletter. They will
      not be passed on to third parties. Also, no comparison will be made
      between the data collected in this way with data that may be collected by
      other components of our site. You can cancel your subscription to this
      newsletter at any time. Details on this matter can be found in the
      confirmation e-mail as well as in each individual newsletter.
    </p>

    <h4>Newsletter tracking</h4>

    <p>
      Our newsletters contain so-called tracking pixels. A tracking pixel is a
      miniature graphic that is embedded in such emails that are sent in HTML
      format to enable log file recording and log file analysis. This enables a
      statistical evaluation of the success or failure of our online marketing
      campaigns. Based on the embedded tracking pixel, we may see if and when an
      e-mail was opened by a data subject, and which links contained in the
      e-mail were called up by the data subject
    </p>

    <p>
      Such personal data collected via the tracking pixels contained in the
      newsletters are stored and evaluated by us in order to optimize the
      newsletter dispatch and to better adapt the content of future newsletters
      to the interests of the data subject. This personal data is not passed on
      to third parties. Data subjects are entitled at any time to revoke the
      separate declaration of consent given in this regard via the double opt-in
      procedure. After a revocation, this personal data will be deleted by the
      person responsible for our data processing. We always interpret a
      withdrawal from the receipt of our newsletter as a revocation.
    </p>

    <h4>Disclosure of Data</h4>

    <p>
      We do not transfer your personal data to third parties for purposes other
      than those listed below. We will only disclose your personal data to third
      parties if you have given your explicit consent to do so in accordance
      with Art. 6 (1) para. 1 lit. a of the DSGVO, if the disclosure is
      necessary for the assertion, exercise or defense of legal claims in
      accordance with Art. 6 (1) para.1 lit. f of the DSGVO and if there is no
      reason to assume that you have an overriding interest worthy of protection
      in the non-disclosure of your data, as well as in the event that a legal
      obligation exists for the disclosure in accordance with Art. 6 para. 1 p.
      1 lit. c of the DSGVO, and if this is legally permissible and necessary
      for the processing of contractual relationships with you according to Art.
      6 para. 1 p. 1 lit. b of the DSGVO.
    </p>

    <h4>Use of Cookies</h4>

    <p>
      We use cookies to operate our website in order to make it more
      user-friendly. Some elements of our website require that the calling
      browser can be identified even after a page change.
    </p>

    <p>
      Cookies are small files that allow specific information related to the
      device to be stored on the user's access device (PC, smartphone or
      similar). On the one hand, they serve the user-friendliness of websites
      and thus serve the users (e.g., storage of login data). On the other hand,
      they serve to collect statistical data on website usage and to be able to
      analyze it for the purpose of improving the offer. Users can influence the
      use of cookies. Most browsers have an option to restrict or completely
      prevent the storage of cookies. However, it should be noted that the use
      and especially the comfort of use will be limited without cookies.
    </p>

    <p>
      The user data collected in this way are pseudonymized by technical
      precautions. Therefore, an assignment of the data to the calling user is
      no longer possible. The data are not stored together with other personal
      data of the users.
    </p>

    <p>
      The legal basis for the processing of personal data using cookies is Art.
      6 (1) lit. f of the DSGVO. The legal basis for the processing of personal
      data using technically necessary cookies is Art. 6 para. 1 lit. f of the
      DSGVO. The legal basis for the processing of personal data using cookies
      for analysis purposes, if the user has given his consent in this regard,
      is Art. 6 (1) lit. a of the DSGVO.
    </p>

    <p>
      The purpose of using technically necessary cookies is to simplify the use
      of websites for users. Some functions of our website cannot be offered
      without the use of cookies. For these, it is necessary that the browser is
      recognized even after a page change.
    </p>

    <p>
      The analysis cookies are used for the purpose of improving the quality of
      our website and its content. Through the analysis cookies, we learn how
      the website is used and can thus constantly optimize our offer.
    </p>

    <p>
      In these purposes also lies our legitimate interest in the processing of
      personal data according to Art. 6 para. 1 lit. f of the DSGVO.
    </p>

    <h3>Third-Party Services</h3>

    <p>
      On our website we have integrated content, services and performances of
      other providers. These are, for example, maps provided by Google Maps,
      videos from YouTube and graphics and images from other websites. In order
      for this data to be called up and displayed in the user's browser, the
      transmission of the IP address is mandatory. The providers (hereinafter
      referred to as "third-party providers") therefore perceive the IP address
      of the respective user.
    </p>

    <p>
      Even though we make great efforts to use exclusively third-party providers
      that only need the IP address to deliver content, we have no influence on
      a possible storage of the IP address. In this case, this process serves
      statistical purposes, among other things. Our users will be informed if we
      become aware that the IP address is stored.
    </p>

    <h4>Use and application of Matomo</h4>

    <p>
      This website uses the open source web analysis service Matomo. Matomo uses
      so-called "cookies". These are text files that are stored on your computer
      and enable an analysis of your use of the website. For this purpose, the
      information generated by the cookie about the use of this website is
      stored on our server. The IP address is anonymized before storage. Matomo
      cookies remain on your terminal device until you delete them.
    </p>

    <p>
      The storage of Matomo cookies is based on Art. 6 para. 1 lit. f of the
      DSGVO. The website operator has a legitimate interest in the anonymized
      analysis of user behavior in order to optimize both its web offering and
      its advertising.
    </p>

    <p>
      Information generated by the cookie about the use of this website will not
      be disclosed to third parties. You may also refuse the use of cookies by
      selecting the appropriate settings on your browser, however, please note
      that if you do this you may not be able to use the full functionality of
      this website. If you do not agree to the storage and use of your data, you
      can deactivate here the storage and use. In this case, an opt-out cookie
      will be stored in your browser, which prevents Matomo from storing usage
      data.
    </p>

    <p>
      If you delete your cookies, this will have the effect that the Matomo
      opt-out cookie will also be deleted. The opt-out must be reactivated when
      you visit our site again.
    </p>

    <!-- <p>< INSTALL Opt-OUT PLUGIN ></p> -->

    <h4>Payment Service Providers</h4>

    <p style="overflow-wrap: anywhere">
      We use external payment service providers. Through their platforms we and
      our users can make payment transactions. These payment providers include
      amongst others Paypal
      (https://www.paypal.com/de/webapps/mpp/ua/privacy-full), Klarna
      (https://www.klarna.com/de/datenschutz/) and Stripe
      (https://stripe.com/de/privacy#translation).
    </p>

    <p>
      As part of the performance of contracts, we use the payment service
      providers on the basis of Art. 6 para. 1 lit. b. of the DSGVO.
      Furthermore, we use external payment service providers on the basis of our
      legitimate interests pursuant to Art. 6 para. 1 lit. b. of the DSGVO in
      order to offer our users effective and secure payment options.
    </p>

    <p>
      Included in the data processed by the payment service providers are
      inventory data, such as name and address, bank data, such as account or
      credit card numbers, passwords, TANs and checksums, as well as the
      information related to contracts, totals and recipients.
    </p>

    <p>
      The information is required to carry out the transactions. However, data
      entered are processed by the payment service providers only and stored
      with them. That means that we do not receive any account or credit card
      related information, but only information regarding confirmation or
      decline of the payment.
    </p>

    <p>
      Under certain circumstances, the payment service providers transmit the
      data to credit agencies. The purpose of this transmission is to check
      identity and creditworthiness. In this regard, we refer to the terms and
      conditions and data protection information of the payment service
      providers.
    </p>

    <p>
      The terms and conditions as well as data protection information of the
      respective payment service providers apply for payment transactions. This
      information can be accessed at the respective websites or transaction
      applications.
    </p>

    <p>
      To this information we also refer for the purpose of more details and
      assertion of revocation and other data subject rights.
    </p>

    <h4>Use of PayPal as a Payment Method</h4>

    <p>
      If you decide to pay with the online payment service provider PayPal
      during your order process, your contact data will be transmitted to PayPal
      as part of the order payment triggered in the ordering process. PayPal is
      an offer of PayPal (Europe) S.à.r.l. & Cie. S.C.A., 22-24 Boulevard Royal,
      L-2449 Luxembourg. PayPal assumes the function of an online payment
      service provider as well as a trustee and offers buyer protection
      services.
    </p>

    <p>
      The personal data transmitted to PayPal are mostly first name, last name,
      address, telephone number, IP address, e-mail address, or other data
      required for order processing, as well as data related to the order, such
      as number of items, item number, invoice amount and taxes in percent,
      billing information, and so on.
    </p>

    <p>
      This data transmission is necessary to process your order with your
      selected payment method, in particular to confirm your identity, to
      administer your payment and the customer relationship.
    </p>

    <p style="overflow-wrap: anywhere">
      However, please note: Personal data may also be transferred by PayPal to
      service providers, subcontractors or other affiliated companies to the
      extent necessary to fulfill the contractual obligations arising from your
      order or to process the personal data on your behalf.
      <br />
      Depending on the payment method selected via PayPal, e.g., invoice or
      direct debit, the personal data transmitted to PayPal will then be
      transmitted by PayPal to credit agencies. This transmission serves to
      check your identity and creditworthiness in relation to the order you have
      placed. Which credit agencies are involved and which data are generally
      collected, processed, stored and passed on by PayPal you can find out in
      the PayPal data protection declaration at
      https://www.paypal.com/de/webapps/mpp/ua/privacy-full.
    </p>

    <h4>Use of Klarna as Payment Method</h4>

    <p>
      If you choose as a payment option with us during the ordering process
      Klarna Invoice or Klarna Installment Purchase of Klarna AB, business
      address Sveavägen 46, 111 34 Stockholm, Sweden, you agree that we collect
      and transmit to Klarna AB the personal data required for processing the
      invoice purchase as well as an identity and credit check. These are in
      particular first and last name, title, address, date of birth, gender,
      email address, IP address, telephone number, cell phone number, as well as
      data necessary for the processing of the purchase on account and data
      related to the order, such as number of items, item number, invoice amount
      and taxes in percent, billing information, bank details, card number,
      expiration date, CCV code, information about goods / services, historical
      information, Information about your previous purchases, payment history,
      any rejections, financial information, information about any credit
      obligations and payment injunctions, information about the interaction
      between you and Klarna Checkout, page load times, download errors and
      methods used to leave the displayed page, electronic communication
      information, receipt confirmations, device information, geographical
      information.
    </p>

    <p>
      This data transfer is necessary to process your purchase with your
      requested invoice processing, in particular to confirm your identity, to
      administer your payment and customer relationship, for customer analysis,
      to administer Klarna's services and for internal processes, including
      troubleshooting, data analysis, internal testing, development, statistical
      purposes, to improve Klarna's services, to ensure, that the necessary
      information is displayed as effectively as possible for you and your
      device, to prevent misuse or improper use of Klarna's Services, as part of
      Klarna's efforts to make the Services as secure as possible, to assess
      which payment methods we can make available to you through Klarna, to
      conduct internal credit assessments, to conduct risk analysis and risk
      management, for business development and to comply with applicable law.
    </p>

    <p>
      In doing so, Klarna has a legitimate interest in the transmission of
      Buyer's personal data and requires it in order to obtain information from
      credit reporting agencies for the purpose of identity and credit
      assessments. In Germany, the aforementioned data may be passed on to the
      following credit reporting agencies:
    </p>

    <ul>
      <li>
        Arvato Infoscore Consumer Data GmbH und Infoscore Consumer Data GmbH,
        Rheinstraße 99, 76532 Baden-Baden
      </li>
      <li>
        Bürgel Wirtschaftsinformationen GmbH & Co. KG, Postfach 5001 66, 22701
        Hamburg
      </li>
      <li>Creditreform Boniversum GmbH, Hellersbergstraße 11, 41460 Neuss</li>

      <li>Deltavista GmbH, Freisinger Landstr. 74 80939 München</li>

      <li>SCHUFA Holding AG, Kormoranweg 5, 65201 Wiesbaden</li>
    </ul>

    <p>
      In addition, Klarna may provide your personal data to other companies
      within the Klarna Group, service providers and subcontractors to the
      extent necessary to fulfill the contractual relationship with you or with
      them.
    </p>

    <p>
      As part of the decision on the establishment, implementation or
      termination of the contractual relationship, Klarna collects and uses
      information on the past payment behavior of Buyer and probability values
      on this behavior in the future. The calculation of this scoring on the
      part of Klarna is carried out on the basis of scientifically recognized
      mathematical-statistical methods.
    </p>

    <p>
      It is possible to revoke your consent to the use of personal data at any
      time to Klarna. However, even in this case Klarna may still be entitled to
      process, use and transfer the personal data, insofar as this is necessary
      for the contractual payment processing by Klarna's services, is required
      by law, or is required by a court or an authority.
    </p>

    <p>
      You can also obtain further information on data protection from Klarna
      directly: Klarna AB Privacy Policy. You can obtain information about the
      personal data stored by Klarna at any time by contacting
      Datenschutz@klarna.de.
    </p>

    <h4>Use of Stripe for Payment Processing</h4>

    <p>
      If you choose during the ordering process a payment method of the payment
      service provider Stripe, the payment will be processed via the payment
      service provider Stripe Payments Europe Ltd, 1 Grand Canal Street Lower,
      Grand Canal Dock, Dublin, Ireland, to whom we pass on your information
      provided during the ordering process together with the information about
      your order (name, address, account number, bank code, possibly credit card
      number, invoice amount, currency and transaction number) in accordance
      with Art. 6 para. 1 lit. b of the DSGVO. You can find more information
      about Stripe's data protection at the URL
      https://stripe.com/de/privacy#translation.
    </p>

    <p>
      Stripe reserves the right to perform a credit check based on
      mathematical-statistical methods in order to preserve the legitimate
      interest in determining the User's ability to pay. The personal data
      necessary for a credit check and obtained in the course of payment
      processing may be transmitted by Stripe to selected credit agencies, which
      Stripe discloses to Users upon request. The credit report may contain
      probability values (so-called score values). Insofar as score values are
      included in the credit rating report, these values are based on a
      scientifically recognized mathematical-statistical procedure. The
      calculation of the score values includes, but is not limited to, address
      data. Stripe uses the result of the credit rating report, where it refers
      to the statistical probability of non-payment, for the purpose of deciding
      on the authorization to use the selected payment method.
    </p>

    <p>
      You can object to this processing of your data at any time by sending a
      message to Stripe or the appointed credit agencies.
    </p>

    <p>
      However, Stripe remains entitled, as the case may be, to process your
      personal data to the extent necessary for payment transactions in
      accordance with the contract.
    </p>

    <h4>
      Cooperation with Data Processors, Jointly Responsible Persons and Third
      Parties
    </h4>

    <p>
      In case, we disclose, in the course of our processing, data to other
      persons and companies (data processors, jointly responsible persons or
      third parties), transfer data to them or otherwise grant them access to
      the data, this will only be done on the basis of legal permission (e.g. if
      a transfer of data to third parties, such as payment service providers, is
      necessary for the performance of the contract), based on consent of Users,
      or on a provision of legal obligations or on the basis of our legitimate
      interests (e.g. when using agents, web hosts, etc.).
    </p>

    <p>
      If we disclose or transfer data to other companies in our group of
      companies or otherwise grant them access, this is done in particular for
      administrative purposes as a legitimate interest and, in addition, on a
      basis that complies with legal requirements.
    </p>

    <h3>Transfers to third countries</h3>

    <p>
      If we process data in a third country (i.e., outside the European Union
      (EU), the European Economic Area (EEA) or the Swiss Confederation) or if
      this is done as part of using third-party services or disclosing, or
      transferring data to other persons or companies, this is done only if
      required to fulfill our (pre)contractual obligations, on the basis of your
      consent, or required due to a legal obligation or on the basis of our
      legitimate interests.
    </p>

    <p>
      Subject to legal or contractual permissions, we process or leave the data
      in a third country only if the legal requirements are met. This means, for
      example, that the processing takes place on the basis of special
      guarantees, such as the officially recognized determination of a level of
      data protection corresponding to the EU (e.g., for the USA by the "Privacy
      Shield") or compliance with officially recognized special contractual
      obligations.
    </p>

    <h3>Data protection for applications and in the application procedure</h3>
    <p>
      We collect and process the personal data of applicants as part of handling
      the application procedure. The processing may also be done electronically.
      This is particularly the case if an applicant submits the respective
      application documents electronically, for example by e-mail or via a web
      form located on our website. If we conclude an employment contract with an
      applicant, the data transmitted will be stored for the purpose of
      processing the employment relationship in compliance with the statutory
      provisions. If we do not conclude an employment contract with the
      applicant, the application documents will be automatically deleted two
      months after notification of the rejection decision, provided that no
      other legitimate interests on our part prevent deletion. Other legitimate
      interests in this sense means, for example, a duty to provide evidence in
      proceedings under the General Equal Treatment Act (AGG).
    </p>

    <h3>Rights of the Data Subject</h3>

    <p>
      You have the right: <br />
      (1) to request information about your personal data processed by us in
      accordance with Art. 15 of the DSGVO. In particular, you can request
      information about the processing purposes, the category of personal data,
      the categories of recipients to whom your data have been or will be
      disclosed, the scheduled storage period, the existence of a right to
      amendment, deletion, restriction of processing or objection, the existence
      of a right of complaint, the origin of your data if it has not been
      collected by us, and the existence of automated decision-making, including
      profiling, and, as the case may be, meaningful information about its
      details; <br />
      (2) in accordance with Art. 16 of the DSGVO, to immediately request the
      correction of incorrect or completion of your personal data stored by us;
      <br />
      (3) pursuant to Art. 17 of the DSGVO, to request the erasure of your
      personal data stored by us, unless the processing is necessary for the
      exercise of the right to freedom of expression and information, for
      compliance with a legal obligation, for reasons of public interest or for
      the establishment, exercise or defense of legal claims; <br />
      (4) pursuant to Art. 18 DSGVO, to request the restriction of the
      processing of your personal data, insofar as the accuracy of the data is
      disputed by you, and the processing therefore is unlawful, but you object
      to its deletion and we no longer need the data, you, however, need the
      data for the assertion, exercise or defense of legal claims, or you have
      objected to the processing pursuant to Art. 21 of the DSGVO; <br />
      (5) pursuant to Art. 20 of the DSGVO, to regain, in a structured, commonly
      used and machine-readable format, your personal data provided to us, or to
      request that it be transferred to another responsible person; <br />
      (6) in accordance with Art. 7 (3) of the DSGVO, to revoke your consent
      once given to us at any time. This has the consequence that we may no
      longer continue the data processing based on this consent for the future;
      and <br />
      (7) complain to a supervisory authority in accordance with Art. 77 of the
      DSGVO. Generally, you can contact for this purpose the supervisory
      authority of your usual place of residence, your workplace or the place of
      our registered office.
    </p>

    <h4>Right of Objection</h4>

    <p>
      You have the right to object at any time, for reasons resulting from your
      particular situation, to the processing of personal data relating to you,
      which is made on the basis of Article 6(1)(e) or (f) of the DSGVO; this
      also applies to profiling based on these provisions.
    </p>

    <p>
      The responsible person will then no longer process your personal data
      unless being in a position to demonstrate compelling legitimate reasons
      for the processing overriding your interests, rights and freedoms, or in
      the case that the processing serves the purpose of asserting, exercising
      or defending legal claims.
    </p>

    <p>
      If the personal data concerning you are processed for the purposes of
      direct marketing, you have the right to object at any time to the
      processing of these data for the purposes of such marketing; this also
      applies to profiling, insofar as it is related to such direct marketing.
    </p>

    <p>
      If you object to the processing for direct marketing purposes, the
      personal data concerning you will no longer be processed for these
      purposes.
    </p>

    <p>
      In connection with the use of information society services you have the
      possibility, notwithstanding Directive 2002/58/EC, to exercise your right
      to object by means of automated procedures using technical specifications.
    </p>

    <p>Automated Decision in Individual Cases including Profiling</p>

    <p>
      You have the right not to be subject to a decision based solely on
      automated processing - including profiling - which produces legal effects
      concerning you or similarly significantly affects you. This does not apply
      if the decision <br />
      (1) is necessary for the conclusion or performance of a contract between
      you and the responsible organization. <br />
      2) is permitted by legal provisions of the European Union or the Member
      States to which the responsible organization is subject and these legal
      provisions contain appropriate measures to protect your rights and
      freedoms as well as your legitimate interests; or <br />
      (3) is made with your express consent. However, these decisions may not be
      based on special categories of personal data pursuant to Art. 9 para. 1 of
      the DSGVO, unless Article 9 para. 2 lit. a or g applies and appropriate
      measures have been taken to protect your rights and freedoms as well as
      your legitimate interests. <br />
      With regard to the cases mentioned in (1) and (3), the responsible
      organization shall take reasonable steps to preserve your rights and
      freedoms as well as your legitimate interests, which include at least the
      right to obtain the intervention of a person on the part of the
      responsible organization for explanation of the organization´s point of
      view, and to contest the organization´s decision. <br />
    </p>

    <p>
      As a responsible enterprise, we renounce automatic decision-making or
      profiling.
    </p>

    <h3>Data Safety</h3>

    <p>
      Within the website visit, we use the widespread SSL procedure (Secure
      Socket Layer) in connection with the highest encryption level supported by
      your browser. Generally, this is a 256-bit encryption. If your browser
      does not support 256-bit encryption, we use 128-bit v3 technology instead.
    </p>

    <p>
      Whether an individual page of our website is encrypted you can recognize
      by the closed key or lock symbol in the lower status bar of your browser.
    </p>

    <p>
      Furthermore, we use appropriate technical and organizational security
      measures to protect your data against accidental or intentional
      manipulation, partial or complete loss, destruction or against
      unauthorized access by third parties. Our security measures are
      continuously improved in line with technological developments.
    </p>

    <h3>Actuality and Alteration of this Privacy Policy</h3>

    <p>
      This data protection declaration is currently valid and has the status of
      April 27, 2021.
    </p>

    <p>
      Due to further development of our website and offers on it or due to
      changed legal or official requirements, it may become necessary to change
      this data protection declaration. You can access and print out the current
      data protection declaration at any time via this address.
    </p>
    


  </div>
</template>

<script>

export default {
  name: "Impressum",
  components: {
  }
};
</script>

<style lang="scss">
.container-data-policy {

  height: 100vh;

  overflow-y: scroll;

  background-color: #27083a;

  padding: 46px 50px 34px 24px;

  color: #ffb0e2;

  .title {
    font-weight: 700;
    font-size: 47px;
    line-height: 56px;

    margin-top: 10px;
    margin-bottom: 30px;
  }

  h4 {
    margin-top: 2rem;
  }

  .date {
    font-size: 16px;
    line-height: 24px;
  }

  .text {
    font-size: 29px;
    line-height: 34px;

    margin-bottom: 20px;
  }

  .subtext {
    font-size: 18px;
    line-height: 27px;

    margin-bottom: 20px;
  }

  .support-logos {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    // margin-top: 1rem;

    > div.logo-content {
      display: block;
      min-width: 100px;
      padding: 10px;
      
      > svg, > img {
        display: block;
        transform: none;
        width: 100%;
        height: auto;
        &.inimusik {
          width: 140px;
          height: auto;
        }
      }

    }
    

    // > div, > img, > svg {
    //   display: block;

    // }

  }
}
</style>