<template>
  <div>
    <div class="forum-details-container" v-if="thread">
      <v-icon class="icon-back" color="ambient" large @click="$router.go(-1)">
        arrow_back
      </v-icon>

      <div class="row col-md-12">
        <h1>Community</h1>
      </div>

      <div class="row simple_discussion">
        <div class="col-md-9 mb-3">
          <div class="card card-body">
            <h2>
              {{ thread.title }}
            </h2>

            <div class="thread-details">
              @{{ thread.user.name }} on
              <span>#{{ thread.forum_category.slug }}</span>
              -
              {{ $moment(thread.created_at).fromNow() }}
              <span v-if="false && thread.solved" class="info-solved">
                <v-icon size="20px" color="#FFB0E2">done</v-icon>
                <!-- <span>Solved</span> -->
              </span>
            </div>

            <span v-if="thread.solved" class="info-solved">
              <v-icon size="20px" color="#FFB0E2">done</v-icon>
              <span class="hidden sm:inline">Solved</span>
            </span>

            <div
              :class="['forum-post', 'card', 'original-poster', { highlighted: post.highlighted }, { pinned: post.pinned }]"
              v-for="post in thread.forum_posts"
              :key="post.id"
            >
              <div class="post-container">
                <div>
                  <div class="card-header">
                    <div class="user">
                      <div>
                        <strong class="forum-post-user">
                          @{{ post.user.name }}
                        </strong>
                        <small>
                          on
                          {{ $moment(post.created_at).format("MMM DD, YYYY") }}:
                        </small>
                      </div>
                    </div>
                  </div>

                  <div class="card-body">
                    <p v-html="nl2br(post.body)"></p>
                  </div>
                </div>
                <div class="post-votes">
                  <v-icon size="20px" :style="post.upvoted_by_current_user ? 'font-weight: bold; opacity: 1;' : ''" :color="post.upvoted_by_current_user ? '#FFB0E2' : '#FFB0E2'" @click="handleUpvote(post)">
                    arrow_upward
                  </v-icon>
                  <span class="hidden sm:inline">{{ post.cached_votes_score }}</span>
                  <v-icon size="20px" :style="post.downvoted_by_current_user ? 'font-weight: bold; opacity: 1;' : ''" :color="post.downvoted_by_current_user ? '#FFB0E2' : '#FFB0E2'" @click="handleDownvote(post)">
                    arrow_downward
                  </v-icon>
                </div>
              </div>

              <a
                class="solved" style="width: fit-content;"
                @click.stop.prevent="handleSolved(post.id)"
                v-if="!thread.solved && user.id === thread.user.id"
              >
                <span class="inline">This solved my question</span>
              </a>

              <div v-if="post.pinned" class="info-pinned">
                <v-icon size="20px" color="#FFB0E2">push_pin</v-icon>
                <!-- <span class="hidden sm:inline">Pinned</span> -->
              </div>


            </div>

            <form
              class="new_forum_post"
              autocomplete="off"
              @submit.stop.prevent="handleSubmit"
            >
              <v-textarea
                v-model="form.body"
                placeholder="Add A comment"
                background-color="#402D4F"
                color="#FFB0E2"
                outlined
              />

              <v-btn color="#5573FF" dark type="submit" class="button-outlined"
                >Comment</v-btn
              >
            </form>
          </div>
        </div>
      </div>

      <div class="whole-footer text-center">© 2024 WHOLE Festival GmbH</div>
    </div>

    <div class="container-loader" v-else>
      <v-progress-circular
        indeterminate
        color="#ffb0e2"
      />
    </div>
  </div>
</template>

<script>
import ForumService from "@/services/forum.service";

export default {
  name: "ThreadNew",
  data: function () {
    return {
      thread: null,
      form: {
        body: "",
      },
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      const response = await ForumService.get(this.$route.params.id);

      console.log(response.data)

      this.thread = response.data;
    },
    async handleSubmit() {
      try {
        const response = await ForumService.createComment(this.thread.id, {
          forum_post: this.form,
        });

        console.log(response.data)

        this.form.body = "";

        this.thread = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async handleSolved(id) {
      try {
        const response = await ForumService.solved(this.thread.id, id);

        this.thread = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async handleUpvote(post) {
      try {
        const response = await ForumService.upvote(this.thread.id, post.id);

        this.thread = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async handleDownvote(post) {
      try {
        const response = await ForumService.downvote(this.thread.id, post.id);

        this.thread = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    nl2br (content) {
      const breakTag = '<br>'
      return (this.removeExtraLineBreaks(content) + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    },
    removeExtraLineBreaks (content) {
      try {
        return content.toString().replace(/[\r\n]{2,}/g, "\n")
      } catch(_) {
        return content.toString()
      }
    }
  },
};
</script>

<style lang="scss">
.forum-details-container {
  color: #ffb0e2;

  height: 100vh;
  overflow-y: scroll;

  margin-top: env(safe-area-inset-top);

  h1 {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  padding: 20px;

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;

    padding: 1.25rem;

    .forum-post {
      padding: 1.25rem 0px;
    }

    background: #27083a;

    .button-outlined {
      width: 100%;
    }

    form {
      .v-text-field--outlined fieldset {
        display: none;
      }

      input,
      textarea {
        color: #ffb0e2;

        padding-left: 10px;

        font-size: 18px;
      }

      ::placeholder,
      label {
        color: #ffb0e2;
      }
    }

    .thread-details {
      padding-bottom: 10px;
      border-bottom: 2px solid #ffb0e23f;
    }

    .solved {
      color: #5573ff;
    }

    .info-solved {
      background-color: #402d4f;

      border-radius: 10px;

      padding: 1px 10px;

      text-align: center;

      font-size: 15px;
    }
  }

  .card-header {
    .user {
      display: flex;

      margin-bottom: 10px;
    }
  }

  .forum-post {
    position: relative;
    border-bottom: 1px solid #ffb0e23f;

    &.highlighted {
      background-color: #653868;
      padding: 0.5rem;
      margin: 1rem 0 !important;
    }

    &.pinned {
      padding: 0.5rem;
      margin: 1rem 0;

      .info-pinned {
        position: absolute;
        top: 10px;
        right: 30px;
      }
    }

    .post-container {
      display: flex;
      justify-content: space-between;

      .post-votes {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-left: 20px;

        button {
          cursor: pointer;
          opacity: 0.6;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}

.container-loader {
  height: 100vh;
  width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
