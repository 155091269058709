<template>
  <div class="container-favs">
    <Header/>

    <tabs-up baseRoute="favs" :category.sync="category"/>

    <program-day
      v-for="(group, index) of groups_favorites"
      :group="group"
      :key="group.date"
      :total="groups_favorites.length"
      :position="index"
      :sendPreviousDay="sendPreviousDay"
      :sendNextDay="sendNextDay"
      />
  </div>
</template>

<script>
  import Header from "@/components/Header";
  import TabsUp from "@/components/TabsUp";
  import ProgramDay from "@/components/ProgramDay";

  import FavoritesService from "@/services/favorites.service";

  export default {
    name: "Favs",
    data: () => ({
      category: 'crew',
      groups_favorites: [],
    }),
    components: {
      Header,
      TabsUp,
      ProgramDay,
    },
    watch: {
      category: function () {
        this.getFavorites()
      },
      '$route.query.category': {
        immediate: true,
        handler: function (val) {
          this.category = val ? val : 'crew'
          // this.getFavorites()
          // console.log('mudei a rota para ', val)
        }
      }
    },
    mounted() {
      this.getFavorites();
    },
    methods: {
      async getFavorites() {
        try {
          const response = await FavoritesService.getAll(this.category);

          this.groups_favorites = response.data;
        } catch (error) {
          console.error(error);
        }
      },
      sendPreviousDay(position) {
        if(position == 0) return;

        const container = this.$el.querySelector(`.program-${position - 1}`);
        if(container) container.scrollIntoView({ behavior: "smooth", block: "start" });
      },
      sendNextDay(position) {
        if(position + 1 == this.groups_favorites.length) return;

        const container = this.$el.querySelector(`.program-${position + 1}`);
        if(container) container.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }
</script>

<style lang="scss">
  .container-favs {
    height: calc(100vh - 150px);

    padding-bottom: 120px;

    margin-top: calc(100px + env(safe-area-inset-top));

    overflow-y: scroll;
  }
</style>
