import Vue from 'vue'
import VueRouter from 'vue-router'

import { App } from '@capacitor/app';

import Schedule from '../views/Schedule';
// import Workshop from '../views/Workshop';

import Welcome from '../views/Welcome';

import LiveUpdates from '../views/LiveUpdates';
import LiveUpdateDetails from '../views/LiveUpdates/Details';

import Lineup from '../views/Informations/Lineup';
import Location from '../views/Informations/Location';
import HealthAndSafety from '../views/Informations/HealthAndSafety';
import Impressum from '../views/Informations/Impressum';
import DataPolicy from '../views/Informations/DataPolicy';
import Faq from '../views/Informations/Faq';
import Terms from '../views/Informations/Terms';
import Privacy from '../views/Informations/Privacy';

import Login from '../views/User/Login'
import Register from '../views/User/Register'
import Profile from '../views/User/Profile'
import UpdateProfile from '../views/User/UpdateProfile'

import ForgotPassword from '../views/User/ForgotPassword';
import ResetPassword from '../views/User/ResetPassword';

import Program from '../views/Tabs/Program'
import ProgramNew from '../views/Tabs/Program/new'
import ProgramV3 from '../views/Tabs/Program/v3'
import Favs from '../views/Tabs/Favs'
import Map from '../views/Tabs/Map'

import Forum from '../views/Tabs/Forum'
import ForumNew from '../views/Tabs/Forum/New';
import ForumDetails from '../views/Tabs/Forum/Details';

import Sos from '../views/Tabs/Sos'

import store from '../store'

Vue.use(VueRouter)

const routes = [{
    path: '/schedule/:id',
    name: 'Schedule',
    component: Schedule
  },
  // {
  //   path: '/workshop/:id',
  //   name: 'Workshop',
  //   component: Workshop
  // },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome
  },
]

const routesInformations = [{
    path: '/live-updates',
    name: 'LiveUpdates',
    component: LiveUpdates
  },
  {
    path: '/live-updates/:id',
    name: 'LiveUpdateDetails',
    component: LiveUpdateDetails
  },
  {
    path: '/lineup',
    name: 'Lineup',
    component: Lineup
  },
  {
    path: '/location',
    name: 'Location',
    component: Location
  },
  {
    path: '/health-and-safety',
    name: 'HealthAndSafety',
    component: HealthAndSafety
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: Impressum
  },
  {
    path: '/data-policy',
    name: 'DataPolicy',
    component: DataPolicy
  },
  {
    path: '/faq',
    name: 'Faq',
    component: Faq
  }
]

const routesUser = [{
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/update-profile',
    name: 'UpdateProfile',
    component: UpdateProfile
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
];

const routesPassword = [{
  path: '/forgot-password',
  name: 'ForgotPassword',
  component: ForgotPassword
},
{
  path: '/reset-password',
  name: 'ResetPassword',
  component: ResetPassword
}
];

const routesTabs = [{
    path: '/program',
    name: 'Program',
    component: Program
  },
  {
    path: '/program_new',
    name: 'ProgramNew',
    component: ProgramNew
  },
  {
    path: '/program_v3',
    name: 'ProgramV3',
    component: ProgramV3
  },
  {
    path: '/favs',
    name: 'Favs',
    component: Favs
  },
  {
    path: '/map',
    name: 'Map',
    component: Map
  },
  {
    path: '/forum',
    name: 'Forum',
    component: Forum
  },
  {
    path: '/forum/new',
    name: 'ForumNew',
    component: ForumNew
  },
  {
    path: '/forum/:id',
    name: 'ForumDetails',
    component: ForumDetails
  },
  {
    path: '/sos',
    name: 'Sos',
    component: Sos
  },
];

const routesPrivate = [...[...routes, ...routesTabs, ...routesInformations].map((r) => r.name), 'Profile'];
const routesAuth = ['Login', 'Register']

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...routes, ...routesInformations, ...routesUser, ...routesPassword, ...routesTabs],
  // scrollBehavior (_to, _from, savedPosition) {
  // //   // console.log(to, from, savedPosition)
  // //   console.log(savedPosition)
  // //   if (to.hash) {
  // //     return {
  // //       selector: to.hash,
  // //       behavior: 'smooth',
  // //     }
  //   if (savedPosition)
  //     return savedPosition
  //    else
  //     return { x: 0, y: 0 }
  // }
});

const scrollableElementSelector = '.container-program'
const scrollPositions = Object.create(null)

router.beforeEach((to, from, next) => {
  // save scroll on our current route
  let mainContentDiv = document.querySelector(scrollableElementSelector)
  if (mainContentDiv !== null) {
    scrollPositions[from.name] = mainContentDiv.scrollTop
  }

  if ((routesPrivate.includes(to.name) || to.path === "/") && !store.getters['user/loggedIn']) {
    next({
      name: "Login"
    })
  } else if ((routesAuth.includes(to.name) || to.path === "/") && store.getters['user/loggedIn']) {
    next({
      name: "ProgramV3"
    })
  } else {
    next();
  }
})

window.addEventListener('popstate', () => {
  setTimeout(() => {
    let currentRouteName = router.history.current.name
    let element = document.querySelector(scrollableElementSelector)
    if (element !== null && currentRouteName in scrollPositions) {
      // localStorage.setItem('scrollPosition', scrollPositions[currentRouteName])
      // fix while still loading - dont work
      // element.style.paddingBottom = `${scrollPositions[currentRouteName]}px`

      setTimeout(() => {
        element.scrollTop = scrollPositions[currentRouteName]
        // element.scrollTo({
        //   top: scrollPositions[currentRouteName],
        //   behavior: "smooth"
        // });
        // localStorage.removeItem('scrollPosition')
      }, 650)
    }
  }, 100)

})

App.addListener('appUrlOpen', function (event) {
  // Example url: https://beerswift.app/reset-password
  // slug = /tabs/tabs2
  const slug = event.url.split('.app')[1];

  // We only push to the route if there is a slug present
  if (slug) {
    router.push({
      path: slug,
    });
  }
});

export default router
